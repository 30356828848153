import { accessLevels, emptyArray } from '@evelia/common/constants'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import { createCachedSelector } from 're-reselect'
import { createSelector } from 'reselect'

import { workSystemTypes } from '../constants'
import {
  getFilterItemsByFieldSelector,
  getFindItemByIdSelector,
  getFindItemsByIdsSelector,
  getItemsFromSearchResultsSelector,
  getMemoSelector,
  getRequestBusyStatusSelector,
  getSubentitySelectors,
  getTableIdsSelector
} from '../helpers/selectorHelpers'
import { initialWorkTableOptions } from '../reducers/workReducer'
import { getCustomersFromArgument } from './customerSelectors'
import { getEmployeeRolesFromArgument } from './employeeRoleSelectors'
import { findCurrentEmployee, findCurrentEmployeeLevel } from './employeeSelectors'
import { findFileLinksByProperty } from './fileSelectors'
import { getTargetsFromArgument } from './targetSelectors'
import { findTicketsOfRelation } from './ticketSelectors'
import { getDefaultWorkCustomStateIds } from './workCustomStateSelectors'

export const getWorkFilesBusyStatus = getRequestBusyStatusSelector(state => state.work.files.metadata)

export const getWorkFromArguments = arg => arg.work ? arg.work.records : arg

export const findWorkWithId = getFindItemByIdSelector(getWorkFromArguments)
export const findWorkWithIds = getFindItemsByIdsSelector(getWorkFromArguments, emptyArray)

export const getWorkByTableIds = getTableIdsSelector('work')

export const {
  getSubItemsOfItem: getFilesOfWork,
  rejectSubItemsOfItem: rejectFilesOfWork
} = getSubentitySelectors('work', 'files', 'files', 'workId', 'fileId')

export const getImportantFilesOfWork = createCachedSelector(
  getFilesOfWork,
  (state, workId) => findFileLinksByProperty(state, 'work', 'workId', workId),
  (files, fileLinks) => {
    const importantFiles = files.filter(file =>
      fileLinks.find(link => link.fileId === file.id).isImportant
    )
    return importantFiles
  }
)((__state, workId) => `${workId}`)

export const findWorkByCustomerId = getFilterItemsByFieldSelector(getWorkFromArguments, 'customerId', Number)

export const findWorkByTargetId = getFilterItemsByFieldSelector(getWorkFromArguments, 'targetId', Number)

export const findMemosByWorkId = getMemoSelector('work', 'workId')

export const getWorkFromSearchResult = getItemsFromSearchResultsSelector('work', getWorkFromArguments, findWorkWithId)

export const {
  getJunctionData: getWorkEmployees,
  getJunctionDataBySubId: getWorkEmployeeData,
  getSubItemsOfItem: getEmployeesOfWork
} = getSubentitySelectors('work', 'employees', 'employees', 'workId', 'employeeId')

export const getEmployeeRolesOfWork = createCachedSelector(
  findWorkWithId,
  getEmployeeRolesFromArgument,
  (work, employeeRoles) => {
    return work?.workEmployeeRoleIds
      ?.map(workEmployeeRoleId => employeeRoles.find(employeeRole => employeeRole.id === workEmployeeRoleId))
      .filter(Boolean) || emptyArray
  }
)((state, workId) => `${workId}`)

export const {
  getJunctionData: getWorkSystemMessages
} = getSubentitySelectors('work', 'systemMessages', 'systemMessages', 'workId', 'systemMessageId')

export const findInstalmentTemplates = createSelector(
  getWorkFromArguments,
  work => work.filter(work => work.isTemplate && work.type === workSystemTypes.WORK_TYPE_INSTALMENT)
)

const initialSummaryStats = {
  _id: null,
  workId: null,
  count: 0,
  expensesSum: 0,
  grossProfitSum: 0,
  invoicedSum: 0,
  notInvoicedSum: 0,
  salaryPriceSum: 0,
  socialCostSalaryPriceSum: 0,
  vatExpensesSum: 0,
  vatGrossProfitSum: 0,
  vatInvoicedSum: 0,
  vatNotInvoicedSum: 0
}

export const findSummaryStatsByWorkId = createCachedSelector(
  state => state.work.summaryStats.records,
  (state, workId) => Number(workId),
  (summaryStats, workId) =>
    summaryStats.find(summaryStats => summaryStats.workId === workId) || initialSummaryStats
)((state, workId) => workId)

export const getWorkTableOptions = createCachedSelector(
  state => state,
  (state, tableIdentifier) => tableIdentifier ?? 'default',
  (state, tableIdentifier) => {
    const tableOptions = state.work.tableOptions[tableIdentifier]
    if(!tableOptions.lastFetched) {
      const defaultWorkCustomStateIds = getDefaultWorkCustomStateIds(state)
      return {
        ...tableOptions,
        filters: {
          ...tableOptions.filters,
          workCustomStateId: defaultWorkCustomStateIds
        }
      }
    }
    return tableOptions
  })((state, tableIdentifier) => tableIdentifier ?? 'default')

export const getInitialWorkTableOptions = createCachedSelector(
  getDefaultWorkCustomStateIds,
  (state, tableIdentifier) => tableIdentifier ?? 'default',
  (defaultWorkCustomStateIds, tableIdentifier) => ({
    ...initialWorkTableOptions[tableIdentifier],
    filters: {
      ...initialWorkTableOptions[tableIdentifier].filters,
      ...initialWorkTableOptions[tableIdentifier].fixedFilters,
      workCustomStateId: tableIdentifier === 'projectWork' ? 2 : defaultWorkCustomStateIds
    }
  })
)((state, tableIdentifier) => tableIdentifier ?? 'default')

export const getInitialWorkQuickFilterTableOptions = createCachedSelector(
  (state, tableIdentifier) => tableIdentifier,
  (state, tableIdentifier) => get(state, 'work').tableOptions,
  (tableIdentifier, tableOptions) => tableOptions[tableIdentifier]
    ? tableOptions[tableIdentifier]
    : null
)((state, tableIdentifier) => tableIdentifier)

export const findProjectByWorkId = createCachedSelector(
  findWorkWithId,
  state => state.projects.records,
  (work, projects) => work ? projects.find(project => project.id === work.projectId) : null
)((state, workId) => `${workId}`)

export const getWorkOfContact = getFilterItemsByFieldSelector(getWorkFromArguments, 'contactId', Number)

export const findWorkExtraInfoByWorkId = createCachedSelector(
  state => state.work.extraInfo,
  (state, workId) => Number(workId),
  (extraInfos, workId) => extraInfos ? extraInfos.find(extraInfo => extraInfo.workId === workId) : null
)((state, workId) => workId)

export const getHasAccessToWork = createCachedSelector(
  findWorkWithId,
  findCurrentEmployee,
  findCurrentEmployeeLevel,
  getWorkEmployees,
  getEmployeeRolesOfWork,
  (work, employee, employeeLevel, workEmployees, workEmployeeRoles) => {
    const employeeAccessLevel = employeeLevel.accessLevel
    const workEmployeeIds = workEmployees.map(workEmployee => workEmployee.employeeId)
    const workEmployeeRoleIds = workEmployeeRoles.map(workEmployeeRole => workEmployeeRole.id)

    const isAtLeastSupervisor = employeeAccessLevel >= accessLevels.SUPERVISOR
    const isWorkOwner = work?.createdBy === employee.id
    const isAttachedToWork = workEmployeeIds.includes(employee.id)
    const hasSufficientRole = workEmployeeRoleIds.some(employeeRoleId => employee.employeeRoleIds.includes(employeeRoleId))
    const workIsWithoutRole = !workEmployeeRoleIds.length
    return isAtLeastSupervisor || isWorkOwner || isAttachedToWork || hasSufficientRole || workIsWithoutRole
  }
)((state, workId) => `${workId}`)

export const findCustomerByWorkId = createCachedSelector(
  findWorkWithId,
  getCustomersFromArgument,
  (work, customers) => {
    return customers.find(customer => customer.id === work?.customerId)
  })((state, workId) => workId)

export const findTargetByWorkId = createCachedSelector(
  findWorkWithId,
  getTargetsFromArgument,
  (work, targets) => {
    return targets.find(target => target.id === work?.targetId)
  })((state, workId) => workId)

export const findWorkOccurrenciesByTemplateWorkId = createCachedSelector(
  getWorkFromArguments,
  (state, templateWorkId) => Number(templateWorkId),
  (work, templateWorkId) => {
    return work.filter(w => w.templateWorkId === templateWorkId)
  }
)((state, workId) => `${workId}`)

export const findWorkTicketsWithSystemType = createCachedSelector(
  (state, workId) => workId != null ? findTicketsOfRelation(state, 'workId', workId) : [],
  tickets => sortBy(tickets.filter(ticket => ticket.ticketSystemType === 'work'), ['endDate', 'startDate', 'id'])
)((state, workId) => `${workId}`)
