import { EVELIA_TOTP_TOKEN_HEADER } from '@evelia/common/constants'
import { InboundInvoiceModel, ReceiverModel } from '@evelia/common/types'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'

import inboundInvoiceActions from '../../actions/inboundInvoiceActions'
import { defaultEmbeddedNormalizer } from '../../helpers/apiHelpers'
import { getBaseQuery, transformErrorResponse } from './apiHelpers'
import { basicApiNotification } from './rtkHelpers'
import { BodyWithID } from './types/helpers'
import { ApprovalPayload, ApproveBody, DisapproveBody } from './types/inboundInvoiceApi'

type ApprovedInboundInvoiceData = {
  paymentDate: string
  isoDate: string
  approvedSumPayments: number
  approvedSumSalaries: number
}

export const inboundInvoiceApi = createApi({
  reducerPath: 'inboundInvoiceApi',
  baseQuery: getBaseQuery('inbound_invoices'),
  endpoints: builder => ({
    getApprovedInboundInvoices: builder.query<{ record: Record<string, ApprovedInboundInvoiceData> }, { paymentRange: [string, string] }>({
      query: query => {
        const q = queryString.stringify(query)
        return `/approved_payments?${q}`
      },
      transformErrorResponse
    }),
    approveInboundInvoice: builder.mutation<ApprovalPayload, BodyWithID<ApproveBody> & { token?: string }>({
      query: ({ id, body, token }) => ({
        url: `/${id}/approve`,
        method: 'POST',
        headers: { [EVELIA_TOTP_TOKEN_HEADER]: token },
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
        successMessage: 'Ostolasku hyväksytty',
        errorMessage: 'Virhe ostolaskua hyväksyttäessä'
      }),
      transformErrorResponse
    }),
    disapproveInboundInvoice: builder.mutation<ApprovalPayload, BodyWithID<DisapproveBody>>({
      query: ({ id, body }) => ({
        url: `/${id}/disapprove`,
        method: 'POST',
        body
      }),
      onQueryStarted: async(__args, { queryFulfilled }) => basicApiNotification(queryFulfilled, {
        successMessage: 'Ostolasku hylätty',
        errorMessage: 'Virhe ostolaskua hylättäessä'
      }),
      transformErrorResponse
    }),
    setOnHold: builder.mutation<{ record: InboundInvoiceModel, _embedded: { receivers: ReceiverModel[] } }, { setHold: boolean, message: string | null, id: number }>({
      query: body => ({
        url: `/${body.id}/hold`,
        method: 'POST',
        body
      }),
      transformErrorResponse
    }),
    setOnHoldBulk: builder.mutation<{ records: InboundInvoiceModel, _embedded: { receivers: ReceiverModel[] } }, { inboundInvoiceIds: number[], message: string | null, setHold: boolean }>({
      query: body => ({
        url: `/hold`,
        method: 'POST',
        body
      }),
      transformErrorResponse
    })
  })
})

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  matcher: inboundInvoiceApi.endpoints.setOnHold.matchFulfilled || inboundInvoiceApi.endpoints.setOnHoldBulk.matchFulfilled,
  effect: (action, listenerApi) => {
    const { data } = defaultEmbeddedNormalizer(action.payload ?? {})
    if(data) {
      listenerApi.dispatch(inboundInvoiceActions.fetchSuccess(data))
    }
  }
})

export const {
  useGetApprovedInboundInvoicesQuery,
  useSetOnHoldMutation,
  useSetOnHoldBulkMutation,
  useApproveInboundInvoiceMutation,
  useDisapproveInboundInvoiceMutation
} = inboundInvoiceApi
export const { middleware: inboundInvoiceApiMiddleware } = listenerMiddleware
