import { accessLevels } from '@evelia/common/constants'
import { miniSerializeError } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import isEmpty from 'lodash/isEmpty'
import {
  all,
  call,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'

import authorActions from '../actions/authorActions'
import configActions from '../actions/configActions'
import employeeActions from '../actions/employeeActions'
import employeeRoleActions from '../actions/employeeRoleActions'
import systemCustomerActions from '../actions/systemCustomerActions'
import whoAmIActions from '../actions/whoAmIActions'
import workCustomStateActions from '../actions/workCustomStateActions'
import workRecordActions from '../actions/workRecordActions'
import { fetchConfigs } from '../api/configApi'
import { vatCodeApi } from '../api/rtk/vatCodeApi'
import { fetchWhoAmI } from '../api/whoAmIApi'
import { watchOnVersionSockets } from '../components/NewVersion'
import { addErrorNotification } from '../helpers/notificationHelpers'
import { clearSagaError, genericSagaErrorHandler, handleSagaError } from '../helpers/sagaHelpers'
import { findCurrentEmployeeLevel } from '../selectors/employeeSelectors'
import { vatClassActions, vatRateActions } from '../slices/vatSlices'
import { connectSocket } from '../socket'

export const handleWhoAmI = function* (data) {
  const {
    employee,
    employeeLevel,
    systemCustomer,
    settings,
    emailSettings,
    smsSettings,
    employeeRoles,
    workCustomStates,
    authors,
    vatClasses,
    vatRates,
    vatCodes,
    ...whoAmI
  } = data

  if(!employee && !employeeLevel && !systemCustomer) {
    return yield put(whoAmIActions.fetchSuccess(whoAmI))
  }

  yield put(authorActions.fetchSuccess(authors))
  yield put(employeeActions.fetchSuccess(employee))
  yield put(employeeActions.employeeLevels.fetchSuccess(employeeLevel))
  yield put(systemCustomerActions.details.fetchSuccess(systemCustomer))
  yield put(systemCustomerActions.settings.fetchSuccess(settings))
  yield put(systemCustomerActions.emailSettings.fetchSuccess(emailSettings))
  yield put(systemCustomerActions.smsSettings.fetchSuccess(smsSettings))
  yield put(employeeRoleActions.fetchSuccess(employeeRoles))
  yield put(whoAmIActions.fetchSuccess(whoAmI))
  yield put(workCustomStateActions.fetchSuccess(workCustomStates))
  yield put(vatClassActions.fetchSuccess(vatClasses.records))
  yield put(vatRateActions.fetchSuccess(vatRates.records))
  yield put(vatCodeApi.util.upsertQueryData('getVatCodes', undefined, vatCodes))

  Sentry.getCurrentScope().setUser({
    ...employee,
    employeeLevel: employeeLevel.name,
    accessLevel: employeeLevel.accessLevel,
    systemCustomerId: systemCustomer.id,
    systemCustomerName: systemCustomer.name
  })

  try {
    yield put(configActions.fetchStart())
    const configs = yield call(fetchConfigs)
    yield put(configActions.fetchSuccess(configs))
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Virhe asetusten noutamisessa')
  }
  const accessLevel = yield select(findCurrentEmployeeLevel)
  if(accessLevel?.accessLevel >= accessLevels.DEFAULT) {
    yield put(workRecordActions.fetchRequest({ force: true, filters: { runningOnly: true } }))
  }
}

export function* handleWhoAmIError(err) {
  yield * handleSagaError(err)
  yield put(whoAmIActions.fetchError(miniSerializeError(err)))
}

export function* whoAmIFetchFlow({ data = {} }) {
  try {
    const currentWhoAmI = yield select(whoAmISelector)
    if((!currentWhoAmI.busy && isEmpty(currentWhoAmI.data)) || data.force) {
      yield * clearSagaError()
      yield put(whoAmIActions.fetchStart())
      const returnData = yield call(fetchWhoAmI)
      yield * handleWhoAmI(returnData)
    }
  } catch(err) {
    yield * handleWhoAmIError(err)

    Sentry.getCurrentScope().setUser(null)
    if(err.response && err.response.status !== 401) {
      let errorMessage = 'Virhe perustietojen noutamisessa'
      if(err.response.status === 429) {
        const { message } = err.json
        errorMessage = message
      }
      addErrorNotification(errorMessage)
    }
  }
}

export const whoAmISelector = state => state.whoAmI

export default function* whoAmISaga() {
  yield takeLatest(whoAmIActions.actionTypes.fetchRequest, whoAmIFetchFlow)
  yield takeLatest(whoAmIActions.actionTypes.fetchSuccess, connectSocket)
  yield all([
    watchOnVersionSockets()()
  ])
}
