import {
  accessLevels,
  factoringProviderIds,
  genericSendStatuses,
  inboundInvoiceAccountingStates,
  inboundInvoiceCostTypes,
  inboundInvoicePaymentStates,
  inboundInvoiceStates as inboundInvoiceBaseStates,
  offerVatTypes,
  Option,
  OptionWithColor,
  projectAdditionalEmployeeTypes,
  TextOption,
  timeRecordAccumulationTypes,
  timeRecordSystemTypes,
  TypeMap,
  vacationAccumulationRightRules
} from '@evelia/common/constants'
import { ValueOf } from 'type-fest'

export * as routePaths from './routes'

export const actionKeys = {
  TARGETS: 'targets',
  TARGET_CONTACTS: 'target_contacts',
  TARGET_FILES: 'target_files',
  TARGET_MEMOS: 'target_memos',
  CUSTOMERS: 'customers',
  CUSTOMER_CONTACTS: 'customer_contacts',
  CUSTOMER_FILES: 'customer_files',
  CUSTOMER_MEMOS: 'customer_memos',
  CUSTOMER_GROUPS: 'CUSTOMER_GROUPS',
  CONTACTS: 'contacts',
  CONTACT_FILES: 'contact_files',
  CONTACT_CUSTOMERS: 'contact_customers',
  CONTACT_TARGETS: 'contact_targets',
  CONTACT_EXTRAS: 'contact_extras',
  WORK: 'work',
  WORK_ROWS: 'work_rows',
  WORK_FILES: 'work_files',
  WORK_MEMOS: 'work_memos',
  WORK_EMPLOYEES: 'WORK_EMPLOYEES',
  WORK_SCHEDULE_EVENTS: 'WORK_SCHEDULE_EVENTS',
  WORK_MACHINES: 'WORK_MACHINES',
  WORK_TYPES: 'WORK_TYPES',
  WORK_SUMMARY_STATS: 'WORK_SUMMARY_STATS',
  WORK_CUSTOM_STATES: 'WORK_CUSTOM_STATES',
  WORK_VIEW_ALL: 'WORK_VIEW_ALL',
  WORK_PURCHASE_PRICES: 'WORK_PURCHASE_PRICES',
  WORK_RECURRENCE_RULES: 'work_recurrence_rules',
  WORK_INVOICING_RULES: 'work_invoicing_rules',
  PRODUCTS: 'products',
  PRODUCT_FILES: 'product_files',
  PRODUCT_LINES: 'product_lines',
  PRODUCT_LINES_SUPPLIER_STATS: 'product_lines_supplier_stats',
  PRODUCT_TASKS: 'product_tasks',
  PRODUCT_WAREHOUSE_STATS: 'product_warehouse_stats',
  INVOICES: 'invoices',
  INVOICE_ACCOUNTINGS: 'invoice_accountings',
  INVOICE_FILES: 'invoice_files',
  INVOICE_FORM_SETTINGS: 'invoice_form_settings',
  INVOICE_MEMOS: 'invoice_memos',
  INTEREST_INVOICING: 'INTEREST_INVOICING',
  WHO_AM_I: 'who_am_i',
  LOGIN: 'login',
  EMPLOYEES: 'employees',
  EMPLOYEE_LEVELS: 'employee_levels',
  EMPLOYEE_SUBCONTRACTORS: 'employee_subcontractors',
  EMPLOYEE_INVITATIONS: 'employee_invitations',
  EMPLOYEE_ROLES: 'employee_roles',
  EMPLOYEE_LICENCES: 'employee_licences',
  EMPLOYEE_LICENCE_TYPES: 'employee_licence_types',
  EMPLOYEE_FILES: 'employee_files',
  EMPLOYEE_MEMOS: 'employee_memos',
  EMPLOYEE_EXTRAS: 'employee_extras',
  FILES: 'files',
  CUSTOMER_RELATION_FILES: 'customer_relation_files',
  PROJECT_RELATION_FILES: 'project_relation_files',
  SYSTEM_CUSTOMER: 'system_customer',
  SYSTEM_CUSTOMER_DETAILS: 'system_customer_details',
  SYSTEM_CUSTOMER_SETTINGS: 'system_customer_settings',
  SYSTEM_CUSTOMER_MAVENTA: 'system_customer_maventa',
  SYSTEM_CUSTOMER_ACCOUNTING: 'system_customer_accounting',
  SYSTEM_CUSTOMER_BANK_ACCOUNTS: 'system_customer_bank_accounts',
  SYSTEM_CUSTOMER_CREDIT_ACTIONS: 'system_customer_credit_actions',
  SYSTEM_CUSTOMER_EMAIL_SETTINGS: 'system_customer_email_settings',
  SYSTEM_CUSTOMER_TERMS_OF_SERVICE: 'system_customer_terms_of_service',
  SYSTEM_CUSTOMER_SMS_SETTINGS: 'system_customer_sms_settings',
  SYSTEM_CUSTOMER_FORM_TEMPLATE: 'system_customer_form_template',
  ACCOUNTS: 'accounts',
  DEFAULT_ACCOUNTS: 'DEFAULT_ACCOUNTS',
  DASHBOARD: 'dashboard',
  SUPPLIERS: 'suppliers',
  SUPPLIER_ORDER: 'supplier_order',
  PRODUCT_TABLE_OPTIONS: 'PRODUCT_TABLE_OPTIONS',
  SUPPLIER_TABLE_OPTIONS: 'SUPPLIER_TABLE_OPTIONS',
  INBOUND_INVOICES: 'inbound_invoices',
  INBOUND_INVOICE_ROWS: 'inbound_invoice_rows',
  INBOUND_INVOICE_FILES: 'inbound_invoice_files',
  INBOUND_INVOICE_MEMOS: 'inbound_invoice_memos',
  INBOUND_INVOICE_APPROVALS: 'inbound_invoice_approvals',
  INBOUND_INVOICE_APPROVERS: 'inbound_invoice_approvers',
  INBOUND_INVOICE_ACCOUNTINGS: 'inbound_invoice_accountings',
  INBOUND_INVOICE_RECURRENCE_RULES: 'inbound_invoice_recurrence_rules',
  INBOUND_INVOICE_RECURRENCE_OCCURRENCIES: 'inbound_invoice_recurrence_occurrencies',
  INBOUND_INVOICE_ACTIONS: 'inbound_invoice_actions',
  RECEIVERS: 'receivers',
  RECEIVER_FILES: 'receiver_files',
  RECEIVER_APPROVERS: 'RECEIVER_APPROVERS',
  PACKET_INDUSTRIES: 'PACKET_INDUSTRIES',
  PRICING_RULE_SETS: 'pricing_rule_sets',
  PRICING_RULE_FALLBACKS: 'PRICING_RULE_FALLBACKS',
  PRICING_RULES: 'pricing_rules',
  INVITATIONS: 'invitations',
  FILL_OUT_FORMS: 'fill_out_forms',
  WORK_RECORD_PRODUCTS: 'work_record_products',
  WORK_RECORDS: 'work_records',
  WORK_RECORD_SALARY_EVENTS: 'WORK_RECORD_SALARY_EVENTS',
  WORK_RECORD_MEMOS: 'WORK_RECORD_MEMOS',
  POSTAL_CODES: 'postal_codes',
  RECEIPT: 'receipt',
  RECEIPT_MEMOS: 'receipt_memos',
  RECEIPT_FILES: 'receipt_files',
  RECEIPT_ACCOUNTINGS: 'receipt_accountings',
  SALARY_TYPES: 'SALARY_TYPES',
  WORK_RECORD_PRODUCT_SALARY_TYPES: 'WORK_RECORD_PRODUCT_SALARY_TYPES',
  WORK_RECORD_PRODUCT_PRICING_RULES: 'WORK_RECORD_PRODUCT_PRICING_RULES',
  PRE_HANDOVER_INSPECTIONS: 'PRE_HANDOVER_INSPECTIONS',
  PRE_HANDOVER_INSPECTION_ROWS: 'PRE_HANDOVER_INSPECTION_ROWS',
  PRE_HANDOVER_INSPECTION_MEMOS: 'WORK_PRE_HANDOVER_INSPECTION_MEMOS',
  PROJECTS: 'projects',
  PROJECT_ADDITIONAL_PERSONS: 'project_additional_persons',
  PROJECT_CONTACTS: 'project_contacts',
  PROJECT_EMPLOYEES: 'project_employees',
  PROJECT_FILES: 'project_files',
  PROJECT_MEMOS: 'project_memos',
  PROJECT_INVOICES: 'project_invoices',
  PROJECT_INBOUND_INVOICES: 'project_inbound_invoices',
  PROJECTS_BUDGET: 'projects_budget',
  PROJECTS_BUDGET_RECURSIVE: 'projects_budget_recursive',
  PROJECTS_BUDGET_BY_DENOMINATION: 'projects_budget_by_denomination',
  PROJECT_EXTRA_BUDGETS: 'project_extra_budgets',
  PROJECT_EXTRA_EXPENSES: 'project_extra_expenses',
  PROJECT_DENOMINATION_BUDGETS: 'project_denomination_budgets',
  PROJECT_TYPES: 'project_types',
  INSTALMENTS: 'instalments',
  FINANCE: 'finance',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  SETTINGS_WIZARD: 'settings_wizard',
  CALENDAR: 'calendar',
  QUICK_PRODUCTS: 'quick_products',
  CASH_RECEIPTS: 'cash_receipts',
  CASH_RECEIPTS_FILES: 'cash_receipts_files',
  CASH_RECEIPTS_PDF: 'cash_receipts_pdf',
  CASH_RECEIPTS_ROWS: 'cash_receipts_rows',
  OFFERS: 'OFFERS',
  OFFER_FILES: 'OFFER_FILES',
  OFFER_MEMOS: 'OFFER_MEMOS',
  OFFER_POSTS: 'OFFER_POSTS',
  OFFER_POST_ROWS: 'OFFER_POST_ROWS',
  OFFER_DEFAULTS: 'OFFER_DEFAULTS',
  OFFER_CUSTOM_STATES: 'OFFER_CUSTOM_STATES',
  PACKETS: 'PACKETS',
  RTV: 'RTV',
  INSTALLATIONS: 'INSTALLATIONS',
  PACKET_INSTALLATIONS: 'PACKET_INSTALLATIONS',
  PACKET_INSTALLATION_PACKETS: 'PACKET_INSTALLATION_PACKETS',
  PACKET_INSTALLATION_TASKS: 'PACKET_INSTALLATION_TASKS',
  PACKET_INSTALLATION_PRODUCTS: 'PACKET_INSTALLATION_PRODUCTS',
  WAGE_INCOME_TYPES: 'wage_income_types',
  SALARY_GROUP: 'salary_group',
  PACKET_MATRICES: 'PACKET_MATRICES',
  SYSTEM_CUSTOMER_ANNUAL_INVOICINGS: 'SYSTEM_CUSTOMER_ANNUAL_INVOICINGS',
  SYSTEM_CUSTOMER_APPROVERS: 'SYSTEM_CUSTOMER_APPROVERS',
  SYSTEM_MESSAGES: 'SYSTEM_MESSAGES',
  SYSTEM_MESSAGES_VIEW_ALL: 'SYSTEM_MESSAGES_VIEW_ALL',
  WORK_SYSTEM_MESSAGES: 'WORK_SYSTEM_MESSAGES',
  EMPLOYEE_SYSTEM_MESSAGES: 'EMPLOYEE_SYSTEM_MESSAGES',
  PRODUCT_GROUPS: 'product_groups',
  SUPPLY_OFFERS: 'SUPPLY_OFFERS',
  SUPPLY_OFFER_MEMOS: 'SUPPLY_OFFER_MEMOS',
  SUPPLY_OFFER_SYSTEM_MESSAGES: 'SUPPLY_OFFER_SYSTEM_MESSAGES',
  SUPPLY_OFFER_ROWS: 'SUPPLY_OFFER_ROWS',
  SUPPLY_OFFER_TYPES: 'SUPPLY_OFFER_TYPES',
  SUPPLY_OFFER_CUSTOM_STATES: 'SUPPLY_OFFER_CUSTOM_STATES',
  SUPPLY_OFFER_FILES: 'supply_offer_files',
  SUPPLY_OFFERS_SUMMARY_STATS: 'SUPPLY_OFFERS_SUMMARY_STATS',
  SUBRECOGNITION: 'SUBRECOGNITION',
  TICKETS: 'tickets',
  TICKET_MEMOS: 'ticket_memos',
  TICKET_STATES: 'ticket_states',
  TICKET_TYPES: 'ticket_types',
  TICKET_EMPLOYEES: 'ticket_employees',
  TICKET_FILES: 'ticket_files',
  TICKET_RECURRENCE_RULES: 'ticket_recurrence_rules',
  PRH: 'prh',
  COST_PROVISIONS: 'cost_provisions',
  WAREHOUSES: 'warehouses',
  WAREHOUSE_ACTIONS: 'warehouse_actions',
  WAREHOUSE_PRODUCT_STATS: 'warehouse_product_stats',
  WORK_QUICK_FILTERS: 'work_quick_filters',
  MAVENTA: 'maventa',
  MACHINES: 'machines',
  MACHINE_MEMOS: 'machine_memos',
  MACHINE_FILES: 'machine_files',
  MACHINE_LOGS: 'machine_logs',
  MACHINE_TYPES: 'machine_types',
  MACHINE_LOG_TYPES: 'machines_machine_log_type',
  MACHINE_RECORDS: 'machine_records',
  MACHINE_RECORD_MEMOS: 'machine_record_memos',
  MACHINE_PRICING_RULES: 'machine_pricing_rules',
  OFFER_POST_STATES: 'OFFER_POST_STATES',
  PACKET_GROUPS: 'packet_groups',
  SUPPLY_OFFER_DEFAULTS: 'SUPPLY_OFFER_DEFAULTS',
  TIME_RECORDS: 'TIME_RECORDS',
  TIME_RECORD_SYSTEM_TYPES: 'TIME_RECORD_SYSTEM_TYPES',
  TIME_RECORD_SITES: 'TIME_RECORD_SITES',
  TIME_RECORD_ACCUMULATIONS: 'TIME_RECORD_ACCUMULATIONS',
  CASH_FLOW_FORECAST_EXTRA_VALUES: 'CASH_FLOW_FORECAST_EXTRA_VALUES',
  CASH_FLOW_FORECAST: 'CASH_FLOW_FORECAST',
  DENOMINATIONS: 'DENOMINATIONS',
  DENOMINATION_PACKETS: 'DENOMINATION_PACKETS',
  DENOMINATION_GROUPS: 'DENOMINATION_GROUPS',
  DENOMINATION_TABLE_OPTIONS: 'DENOMINATION_TABLE_OPTIONS',
  FORM_TYPES: 'FORM_TYPES',
  INVOICE_FORM_TYPES: 'INVOICE_FORM_TYPES',
  INBOUND_MESSAGES: 'INBOUND_MESSAGES',
  WORK_EDIT_PRICES: 'WORK_EDIT_PRICES',
  COST_CENTRES: 'COST_CENTRES',
  COST_CENTRE_GROUPS: 'COST_CENTRE_GROUPS',
  COST_CENTRE_TABLE_OPTIONS: 'COST_CENTRE_TABLE_OPTIONS',
  INVOICING_TYPE: 'INVOICING_TYPE',
  AUTHORS: 'AUTHORS',
  INBOUND_INVOICE_SALARIES: 'INBOUND_INVOICE_SALARIES',
  FORM_TEMPLATES: 'form_templates',
  WORK_FORM_TEMPLATES: 'work_form_templates',
  WORK_FORMS: 'work_forms',
  WORK_FORMS_PDF: 'work_forms_pdf',
  WORK_TICKET_TEMPLATE_LISTS: 'work_ticket_template_lists',
  MAPS: 'MAPS',
  SALARIES: 'SALARIES',
  SALARY_CALCULATION: 'SALARY_CALCULATION',
  SALARY_CALCULATION_SETTINGS: 'SALARY_CALCULATION_SETTINGS',
  PURCHASE_ORDERS: 'PURCHASE_ORDERS',
  PURCHASE_ORDER_MEMOS: 'PURCHASE_ORDER_MEMOS',
  PURCHASE_ORDER_STATES: 'PURCHASE_ORDER_STATES',
  USER_ACCOUNT: 'USER_ACCOUNT'
} as const

export const featureKeys = {
  RECURRENCES: 'RECURRENCES', // Controls recurring work, invoices, tickets etc,
  SALARY_CALCULATION: actionKeys.SALARY_CALCULATION
} as const

const constants = {
  actionKeys
} as const

export default constants

export const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',

  SWITCH_SYSTEM_CUSTOMER_REQUEST: 'SWITCH_SYSTEM_CUSTOMER_REQUEST',
  SWITCH_SYSTEM_CUSTOMER_START: 'SWITCH_SYSTEM_CUSTOMER_START',
  SWITCH_SYSTEM_CUSTOMER_SUCCESS: 'SWITCH_SYSTEM_CUSTOMER_SUCCESS',
  SWITCH_SYSTEM_CUSTOMER_ERROR: 'SWITCH_SYSTEM_CUSTOMER_ERROR',

  LINK_SYSTEM_CUSTOMER_REQUEST: 'LINK_SYSTEM_CUSTOMER_REQUEST',
  LINK_SYSTEM_CUSTOMER_START: 'LINK_SYSTEM_CUSTOMER_START',
  LINK_SYSTEM_CUSTOMER_SUCCESS: 'LINK_SYSTEM_CUSTOMER_SUCCESS',
  LINK_SYSTEM_CUSTOMER_ERROR: 'LINK_SYSTEM_CUSTOMER_ERROR',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',

  PASSWORD_RESET_REQUEST_REQUEST: 'PASSWORD_RESET_REQUEST_REQUEST',
  PASSWORD_RESET_REQUEST_START: 'PASSWORD_RESET_REQUEST_START',
  PASSWORD_RESET_REQUEST_SUCCESS: 'PASSWORD_RESET_REQUEST_SUCCESS',
  PASSWORD_RESET_REQUEST_ERROR: 'PASSWORD_RESET_REQUEST_ERROR',

  PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_START: 'PASSWORD_RESET_START',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_ERROR: 'PASSWORD_RESET_ERROR',

  PRODUCTS_IMPORT_REQUEST: 'PRODUCTS_IMPORT_REQUEST',
  INVOICE_PAYMENTS_IMPORT_REQUEST: 'INVOICE_PAYMENTS_IMPORT_REQUEST',
  INVOICE_PATCH_ACTION_REQUEST: 'INVOICE_PATCH_ACTION_REQUEST',

  CHARGE_INVOICE_REQUEST: 'CHARGE_INVOICE_REQUEST',
  CHARGE_INVOICE_START: 'CHARGE_INVOICE_START',
  CHARGE_INVOICE_SUCCESS: 'CHARGE_INVOICE_SUCCESS',
  CHARGE_INVOICE_ERROR: 'CHARGE_INVOICE_ERROR',

  DOWNLOAD_PDF_INVOICE_REQUEST: 'DOWNLOAD_PDF_INVOICE_REQUEST',
  DOWNLOAD_PDF_INVOICE_START: 'DOWNLOAD_PDF_INVOICE_START',
  DOWNLOAD_PDF_INVOICE_SUCCESS: 'DOWNLOAD_PDF_INVOICE_SUCCESS',
  DOWNLOAD_PDF_INVOICE_ERROR: 'DOWNLOAD_PDF_INVOICE_ERROR',

  REPORTS_FETCH_REQUEST: 'REPORTS_FETCH_REQUEST',
  REPORTS_FETCH_START: 'REPORTS_FETCH_START',
  REPORTS_FETCH_SUCCESS: 'REPORTS_FETCH_SUCCESS',
  REPORTS_FETCH_ERROR: 'REPORTS_FETCH_ERROR',

  REPORTS_GENERATE_REQUEST: 'REPORTS_GENERATE_REQUEST',
  REPORTS_GENERATE_START: 'REPORTS_GENERATE_START',
  REPORTS_GENERATE_SUCCESS: 'REPORTS_GENERATE_SUCCESS',
  REPORTS_GENERATE_ERROR: 'REPORTS_GENERATE_ERROR',

  REPORTS_GENERATE_REPORT_EXPORT_REQUEST: 'REPORTS_GENERATE_REPORT_EXPORT_REQUEST',

  INBOUND_INVOICE_POST_ACTION_REQUEST: 'INBOUND_INVOICE_POST_ACTION_REQUEST',
  INBOUND_INVOICE_C2B_IMPORT_REQUEST: 'INBOUND_INVOICE_C2B_IMPORT_REQUEST',

  INBOUND_INVOICES_FETCH_FROM_MAVENTA_REQUEST: 'INBOUND_INVOICES_FETCH_FROM_MAVENTA_REQUEST',
  INBOUND_INVOICES_FETCH_FROM_MAVENTA_START: 'INBOUND_INVOICES_FETCH_FROM_MAVENTA_START',
  INBOUND_INVOICES_FETCH_FROM_MAVENTA_SUCCESS: 'INBOUND_INVOICES_FETCH_FROM_MAVENTA_SUCCESS',
  INBOUND_INVOICES_FETCH_FROM_MAVENTA_ERROR: 'INBOUND_INVOICES_FETCH_FROM_MAVENTA_ERROR',

  INBOUND_INVOICES_APPROVE_REQUEST: 'INBOUND_INVOICES_APPROVE_REQUEST',
  INBOUND_INVOICES_APPROVE_START: 'INBOUND_INVOICES_APPROVE_START',
  INBOUND_INVOICES_APPROVE_SUCCESS: 'INBOUND_INVOICES_APPROVE_SUCCESS',
  INBOUND_INVOICES_APPROVE_ERROR: 'INBOUND_INVOICES_APPROVE_ERROR',

  INBOUND_INVOICES_ASSIGN_ROWS_REQUEST: 'INBOUND_INVOICES_ASSIGN_ROWS_REQUEST',
  INBOUND_INVOICES_ASSIGN_ROWS_START: 'INBOUND_INVOICES_ASSIGN_ROWS_START',
  INBOUND_INVOICES_ASSIGN_ROWS_SUCCESS: 'INBOUND_INVOICES_ASSIGN_ROWS_SUCCESS',
  INBOUND_INVOICES_ASSIGN_ROWS_ERROR: 'INBOUND_INVOICES_ASSIGN_ROWS_ERROR',

  WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_REQUEST: 'WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_REQUEST',
  WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_START: 'WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_START',
  WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_SUCCESS: 'WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_SUCCESS',
  WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_ERROR: 'WORK_ROWS_CREATE_FROM_INBOUND_INVOICE_ROWS_ERROR',

  WORK_SHOW_MORE_SEARCH_RESULTS_REQUEST: 'WORK_SHOW_MORE_SEARCH_RESULTS_REQUEST',

  CONFIGS_FETCH_REQUEST: 'CONFIGS_FETCH_REQUEST',
  CONFIGS_FETCH_START: 'CONFIGS_FETCH_START',
  CONFIGS_FETCH_SUCCESS: 'CONFIGS_FETCH_SUCCESS',
  CONFIGS_FETCH_ERROR: 'CONFIGS_FETCH_ERROR',

  PAYMENT_CREATE_REQUEST: 'PAYMENT_CREATE_REQUEST',
  PAYMENT_CREATE_START: 'PAYMENT_CREATE_START',
  PAYMENT_CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
  PAYMENT_CREATE_ERROR: 'PAYMENT_CREATE_ERROR',

  WORK_PATCH_ACTION_REQUEST: 'WORK_PATCH_ACTION_REQUEST',
  WORK_POST_ACTION_REQUEST: 'WORK_POST_ACTION_REQUEST',
  WORK_ACTION_REQUEST: 'WORK_ACTION_REQUEST',

  WORK_ROW_PUT_ACTION_REQUEST: 'WORK_ROW_PUT_ACTION_REQUEST',
  WORK_ROW_POST_ACTION_REQUEST: 'WORK_ROW_POST_ACTION_REQUEST',

  SAGA_ERROR_HANDLE: 'SAGA_ERROR_HANDLE',
  SAGA_ERROR_CLEAR: 'SAGA_ERROR_CLEAR',

  CREATE_CREDIT_INVOICE_REQUEST: 'CREATE_CREDIT_INVOICE_REQUEST',

  ACTION_PROMPT_SHOW: 'ACTION_PROMPT_SHOW',
  ACTION_PROMPT_HIDE: 'ACTION_PROMPT_HIDE',

  INVITATIONS_UNSUBSCRIBE: 'INVITATIONS_UNSUBSCRIBE',

  EMPLOYEE_WORK_FETCH_REQUEST: 'EMPLOYEE_WORK_FETCH_REQUEST',

  PROJECTS_FETCH_ACTION_REQUEST: 'PROJECTS_FETCH_ACTION_REQUEST',
  PROJECT_API_ACTION_REQUEST: 'PROJECT_API_ACTION_REQUEST',
  PROJECTS_CREATE_RTV_REPORT: 'PROJECTS_CREATE_RTV_REPORT',
  PROJECT_GENERATE_REPORT_REQUEST: 'PROJECT_GENERATE_REPORT_REQUEST',

  REPLACE_HISTORY: 'REPLACE_HISTORY',
  REMINDER_INVOICES_CREATE_REQUEST: 'REMINDER_INVOICES_CREATE_REQUEST',
  CONSOLIDATED_INVOICE_CREATE_REQUEST: 'CONSOLIDATED_INVOICE_CREATE_REQUEST',

  OFFER_ACTION_REQUEST: 'OFFER_ACTION_REQUEST',
  OFFER_PATCH_ACTION_REQUEST: 'OFFER_PATCH_ACTION_REQUEST',
  OFFER_POST_ROWS_ACTION_REQUEST: 'OFFER_POST_ROWS_ACTION_REQUEST',
  OFFER_GENERATE_REPORT_REQUEST: 'OFFER_GENERATE_REPORT_REQUEST',

  SYNC_BANK_ACCOUNT_BALANCE: 'SYNC_BANK_ACCOUNT_BALANCE',
  AUTHENTICATE_STUL_REQUEST: 'AUTHENTICATE_STUL_REQUEST',

  INBOUND_INVOICE_ROWS_PUT_ACTION_REQUEST: 'INBOUND_INVOICE_ROWS_PUT_ACTION_REQUEST',

  PRE_HANDOVER_INSPECTION_ROWS_PUT_ACTION_REQUEST: 'PRE_HANDOVER_INSPECTION_ROWS_PUT_ACTION_REQUEST',
  PRE_HANDOVER_INSPECTION_PDF_POST_REQUEST: 'PRE_HANDOVER_INSPECTION_PDF_POST_REQUEST',
  FILL_OUT_FORM_PDF_POST_REQUEST: 'FILL_OUT_FORM_PDF_POST_REQUEST',

  WORK_INSTALMENT_IMPORT_ROWS_REQUEST: 'WORK_INSTALMENT_IMPORT_ROWS_REQUEST',

  WORK_RECORD_PRODUCT_PATCH_ACTION_REQUEST: 'WORK_RECORD_PRODUCT_PATCH_ACTION_REQUEST',
  WORK_RECORD_PATCH_ACTION_REQUEST: 'WORK_RECORD_PATCH_ACTION_REQUEST',

  PRODUCT_PATCH_ACTION_REQUEST: 'PRODUCT_PATCH_ACTION_REQUEST',
  PRODUCT_POST_ACTION_REQUEST: 'PRODUCT_POST_ACTION_REQUEST',

  SUPPLY_OFFER_ROW_PUT_ACTION_REQUEST: 'SUPPLY_OFFER_ROW_PUT_ACTION_REQUEST',
  SUPPLY_OFFER_ROW_POST_ACTION_REQUEST: 'SUPPLY_OFFER_ROW_POST_ACTION_REQUEST',
  SUPPLY_OFFER_PATCH_ACTION_REQUEST: 'SUPPLY_OFFER_PATCH_ACTION_REQUEST',
  SUPPLY_OFFER_ACTION_REQUEST: 'SUPPLY_OFFER_ACTION_REQUEST',

  WAREHOUSE_GENERATE_REPORT_REQUEST: 'WAREHOUSE_GENERATE_REPORT_REQUEST',
  WAREHOUSE_REPORT_IMPORT_REQUEST: 'WAREHOUSE_REPORT_IMPORT_REQUEST',

  SYSTEM_MESSAGE_PATCH_ACTION_REQUEST: 'SYSTEM_MESSAGE_PATCH_ACTION_REQUEST',

  OFFER_POST_PATCH_ACTION_REQUEST: 'OFFER_POST_PATCH_ACTION_REQUEST',
  OFFER_POST_IMPORT_ROWS_REQUEST: 'OFFER_POST_IMPORT_ROWS_REQUEST',
  OFFER_POST_ROW_PUT_ACTION_REQUEST: 'OFFER_POST_ROW_PUT_ACTION_REQUEST',

  TIME_RECORD_SYSTEM_TYPE_POST_ACTION_REQUEST: 'TIME_RECORD_SYSTEM_TYPE_POST_ACTION_REQUEST',

  SET_TICKET_COMPLETED: 'set_ticket_completed',

  PRODUCT_LINES_DELETE_NET_PRICES_REQUEST: 'PRODUCT_LINES_DELETE_NET_PRICES_REQUEST',

  EADDRESS_FETCH_REQUEST: 'EADDRESS_FETCH_REQUEST',
  EADDRESS_FETCH_START: 'EADDRESS_FETCH_START',
  EADDRESS_FETCH_SUCCESS: 'EADDRESS_FETCH_SUCCESS',
  EADDRESS_FETCH_ERROR: 'EADDRESS_FETCH_ERROR',

  DENOMINATION_PACKETS_POST_ACTION_REQUEST: 'DENOMINATION_PACKETS_POST_ACTION_REQUEST',
  INBOUND_MESSAGES_FETCH_REQUEST: 'INBOUND_MESSAGES_FETCH_REQUEST',
  INBOUND_MESSAGES_FETCH_START: 'INBOUND_MESSAGES_FETCH_START',
  INBOUND_MESSAGES_FETCH_SUCCESS: 'INBOUND_MESSAGES_FETCH_SUCCESS',
  INBOUND_MESSAGES_FETCH_ERROR: 'INBOUND_MESSAGES_FETCH_ERROR',

  TIME_RECORD_ACCUMULATION_UPDATE_ACTION_REQUEST: 'TIME_RECORD_ACCUMULATION_UPDATE_ACTION_REQUEST',

  PURCHASE_ORDER_PATCH_ACTION_REQUEST: 'PURCHASE_ORDER_PATCH_ACTION_REQUEST'
} as const

export const EMAIL_SUPPORT = 'tuki@evelia.fi'
export const PHONE_SUPPORT = '010 3377 255'
export const EMAIL_SALES = 'myynti@evelia.fi'
export const PHONE_SALES = '010 3377 333'

export const workPatchActions = {
  MARK_REVERSED_VAT: 'mark_reversed_vat',
  MARK_EU_SALES: 'mark_eu_sales',
  TOGGLE_OPEN: 'toggle_open',
  SET_WORK_CUSTOM_STATE: 'set_work_custom_state',
  SET_WORK_FIXED_PRICE: 'set_work_fixed_price',
  CREATE_SUPPLY_OFFER_FROM_WORK: 'create_supply_offer_from_work',
  REPRICE: 'reprice'
} as const

export const workApiActions = {
  TEMPLATES: 'templates',
  INSTALMENTS: 'instalments',
  INSTALMENT_TEMPLATES: 'instalment_templates',
  CONFIRMATIONS: 'confirmations'
} as const

export const workRowApiActions = {
  UPDATE_STATES: 'update_states',
  BULK: 'bulk',
  REPRICE: 'reprice'
} as const

export const supplyOfferRowApiActions = {
  UPDATE_STATES: 'update_states',
  BULK: 'bulk',
  REPRICE: 'reprice'
} as const

export const invoiceApiActions = {
  PROHIBITIONS: 'prohibitions',
  REMINDERS: 'reminders'
} as const

export const keyCodes = {
  BACKSPACE: 8,
  SPACE: 32,
  RIGHT: 39,
  LEFT: 37,
  ESC: 27,
  UP: 38,
  DOWN: 40,
  COMMA: 188,
  ENTER: 13,
  TAB: 9
} as const

export const memoTypeIds = {
  MEMO_ADMIN: 1,
  MEMO_SUPERVISOR: 2,
  MEMO_WORK: 3,
  MEMO_INVOICING: 4,
  MEMO_RECEIVER: 5,
  MEMO_INBOUND_INVOICING: 6,
  MEMO_APPROVAL: 7,
  MEMO_INFO: 8,
  MEMO_PROJECT: 9,
  MEMO_SUPPLY_OFFER: 10,
  MEMO_TICKET: 11,
  MEMO_INTERNAL: 12
} as const

export const memoTypes: TypeMap<typeof memoTypeIds, { header: string, urlPart: string, minAccessLevel: ValueOf<typeof accessLevels> }> = {
  [memoTypeIds.MEMO_ADMIN]: {
    header: 'Sisäinen / Hallinto',
    urlPart: 'admin',
    minAccessLevel: accessLevels.ADMINISTRATION
  },
  [memoTypeIds.MEMO_SUPERVISOR]: {
    header: 'Sisäinen / Työnjohto',
    urlPart: 'supervisor',
    minAccessLevel: accessLevels.SUPERVISOR
  },
  [memoTypeIds.MEMO_WORK]: {
    header: 'Työn ohjeistus',
    urlPart: 'work',
    minAccessLevel: accessLevels.USER_WITHOUT_PRICES
  },
  [memoTypeIds.MEMO_INVOICING]: {
    header: 'Laskutuksen ohjeistus',
    urlPart: 'invoicing',
    minAccessLevel: accessLevels.SUPERVISOR
  },
  [memoTypeIds.MEMO_RECEIVER]: {
    header: 'Saaja',
    urlPart: 'receiver',
    minAccessLevel: accessLevels.SUPERVISOR
  },
  [memoTypeIds.MEMO_INBOUND_INVOICING]: {
    header: 'Ostolasku',
    urlPart: 'inbound_invoicing',
    minAccessLevel: accessLevels.SUPERVISOR
  },
  [memoTypeIds.MEMO_APPROVAL]: {
    header: 'Hyväksyntä',
    urlPart: 'approval',
    minAccessLevel: accessLevels.SUPERVISOR
  },
  [memoTypeIds.MEMO_INFO]: {
    header: 'Tietoja',
    urlPart: 'info',
    minAccessLevel: accessLevels.USER_WITHOUT_PRICES
  },
  [memoTypeIds.MEMO_PROJECT]: {
    header: 'Projekti',
    urlPart: 'project',
    minAccessLevel: accessLevels.USER_WITHOUT_PRICES
  },
  [memoTypeIds.MEMO_SUPPLY_OFFER]: {
    header: 'Tarviketarjous',
    urlPart: 'supply_offer',
    minAccessLevel: accessLevels.USER_WITHOUT_PRICES
  },
  [memoTypeIds.MEMO_TICKET]: {
    header: 'Tehtävät',
    urlPart: 'ticket',
    minAccessLevel: accessLevels.USER_WITHOUT_PRICES
  },
  [memoTypeIds.MEMO_INTERNAL]: {
    header: 'Muutoslogi',
    urlPart: 'internal',
    minAccessLevel: accessLevels.USER
  }
}

export const MIN_SAFE_INTEGER = -(Math.pow(2, 53) - 1) // js implementation not supported in IE
export const MAX_SAFE_INTEGER = Math.pow(2, 53) - 1
export const NEW_ENTRY_ID = MIN_SAFE_INTEGER

const baseInboundInvoiceStates = {
  ...inboundInvoiceBaseStates,
  ...inboundInvoicePaymentStates,
  ...inboundInvoiceAccountingStates
} as const

export const inboundInvoiceStates = {
  ...baseInboundInvoiceStates,
  PAID_STATUSES: [
    baseInboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PAID_MANUALLY,
    baseInboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_PAID,
    baseInboundInvoiceStates.INBOUND_INVOICE_PAYMENT_STATE_OVERPAID
  ],
  UNAPPROVED_STATUSES: [
    baseInboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED,
    baseInboundInvoiceStates.INBOUND_INVOICE_STATE_RECEIVED_FULL,
    baseInboundInvoiceStates.INBOUND_INVOICE_STATE_WAITING_APPROVAL
  ]
} as const

export const invoiceInternalStatusTypes = {
  DEFAULT: 'default',
  ARCHIVED: 'archived',
  PARTIALLY_PAID: 'partially_paid',
  OVER_PAID: 'over_paid',
  COMPLETED: 'completed',
  LATE: 'late',
  INVOICED: 'invoiced',
  NOT_INVOICED: 'not_invoiced',
  FALL_DUE: 'fall_due'
}

export const invoiceInternalStatuses: TypeMap<typeof invoiceInternalStatusTypes, { _status: number, title: string, color: string }> = {
  [invoiceInternalStatusTypes.DEFAULT]: { _status: 0, title: 'Suoritettu', color: 'success' },
  [invoiceInternalStatusTypes.ARCHIVED]: { _status: 1, title: 'Arkistoitu', color: 'info' },
  [invoiceInternalStatusTypes.PARTIALLY_PAID]: { _status: 2, title: 'Osittain suoritettu', color: 'warning' },
  [invoiceInternalStatusTypes.OVER_PAID]: { _status: 3, title: 'Ylisuoritettu', color: 'warning' },
  [invoiceInternalStatusTypes.COMPLETED]: { _status: 4, title: 'Suoritettu', color: 'success' },
  [invoiceInternalStatusTypes.LATE]: { _status: 5, title: 'Myöhässä', color: 'danger' },
  [invoiceInternalStatusTypes.INVOICED]: { _status: 6, title: 'Laskutettu', color: 'info' },
  [invoiceInternalStatusTypes.NOT_INVOICED]: { _status: 7, title: 'Ei laskutettu', color: 'warning' },
  [invoiceInternalStatusTypes.FALL_DUE]: { _status: 8, title: 'Erääntymässä', color: 'warning' }
}

export const dashboardStatusTypes = {
  INVOICED: 'invoiced',
  PAYABLE: 'payable',
  LATE: 'late',
  FALL_DUE: 'fall_due',
  NOT_INVOICED: 'not_invoiced'
} as const

export const reminderInvoiceStates = {
  REMINDER_SENT: { title: 'Muistutus lähetetty', color: 'info' }
}

export const invoiceTypes = {
  INVOICE_TYPE_INVOICE: 'invoice',
  INVOICE_TYPE_CREDIT: 'credit',
  INVOICE_TYPE_REMINDER: 'reminder',
  INVOICE_TYPE_INTEREST: 'interest',
  INVOICE_TYPE_CONSOLIDATED: 'consolidated'
} as const

const inboundInvoiceCostTypeMap: TypeMap<typeof inboundInvoiceCostTypes, TextOption> = {
  [inboundInvoiceCostTypes.INBOUND_INVOICE_COST_TYPE_DEFAULT]: { text: 'Oletus' },
  [inboundInvoiceCostTypes.INBOUND_INVOICE_COST_TYPE_SUBCONTRACTING]: { text: 'Alihankinta' }
}

export const inboundInvoiceCostTypeOptions = Object.entries(inboundInvoiceCostTypeMap).map(([value, typeData]) => ({ value, text: typeData.text }))

export const invoiceAccountingSources = {
  INITIAL: 'initial',
  BANKSON: 'bankson',
  MANUAL: 'manual',
  CREDIT: 'credit',
  DISCOUNT: 'discount'
} as const

export const accountRoles = {
  ACCOUNTING_ROLE_DEFAULT: 0,
  ACCOUNTING_ROLE_RECEIVABLE: 1,
  ACCOUNTING_ROLE_PAYABLE: 2,
  ACCOUNTING_ROLE_SETTLEMENT: 3,
  ACCOUNTING_ROLE_PAYMENT: 4
} as const

export const accountingStatuses = {
  ACCOUNTING_STATUS_WAITING_PAYMENT: 'waiting_payment',
  ACCOUNTING_STATUS_OK: 'ok'
} as const

export const invoiceStates = {
  INVOICE_STATE_PARTIALLY_PAID: 0,
  INVOICE_STATE_COMPLETED: 1,
  INVOICE_STATE_OVER_PAID: 2
} as const

export const workStates = {
  WORK_STATE_CREATED: 0,
  WORK_STATE_STARTED: 1,
  WORK_STATE_INTERRUPTED: 2,
  WORK_STATE_CLOSED: 3,
  WORK_STATE_CONFIRMATION_REQUESTED: 4
} as const

export const workStateMap: TypeMap<typeof workStates, { text: string, color: string }> = {
  [workStates.WORK_STATE_CREATED]: {
    text: 'Avoin',
    color: 'warning'
  },
  [workStates.WORK_STATE_STARTED]: {
    text: 'Aloitettu',
    color: 'success'
  },
  [workStates.WORK_STATE_INTERRUPTED]: {
    text: 'Keskeytetty',
    color: 'danger'
  },
  [workStates.WORK_STATE_CLOSED]: {
    text: 'Päättynyt',
    color: 'info'
  },
  [workStates.WORK_STATE_CONFIRMATION_REQUESTED]: {
    text: 'Odottaa hyväksyntää',
    color: 'danger'
  }
}

export const workSystemTypes = {
  WORK_TYPE_DEFAULT: 'work',
  WORK_TYPE_INSTALMENT: 'instalment',
  WORK_TYPE_PROJECT: 'project'
} as const

export const accountTypes = {
  BALANCE: 0,
  SALES: 1,
  PURCHASES: 2
} as const

export const accountTypeTitleMap: TypeMap<typeof accountTypes, string> = {
  [accountTypes.BALANCE]: 'Tase',
  [accountTypes.SALES]: 'Myynti',
  [accountTypes.PURCHASES]: 'Osto'
}

export const uiTypes = {
  PROMPT_SWITCH_SYSTEM_CUSTOMER: 'PROMPT_SWITCH_SYSTEM_CUSTOMER',
  PROMPT_INBOUND_INVOICE_ROW_UNASSIGN_WORK: 'PROMPT_INBOUND_INVOICE_ROW_UNASSIGN_WORK',
  PROMPT_DELETE_WORK_RECORD: 'PROMPT_DELETE_WORK_RECORD',
  PROMPT_DELETE_MACHINE_RECORD: 'PROMPT_DELETE_MACHINE_RECORD',
  PROMPT_SUPPLY_OFFER_SWITCH_VAT: 'PROMPT_SUPPLY_OFFER_SWITCH_VAT',
  SHOW_WAREHOUSE_INVENTORY_ERRORS: 'SHOW_WAREHOUSE_INVENTORY_ERRORS',
  PROMPT_WORK_CHANGES: 'PROMPT_WORK_CHANGES'
} as const

export const productSalesInvoicing = {
  PRODUCT_SALES_INVOICED: 1,
  PRODUCT_SALES_NOT_INVOICED: 2,
  PRODUCT_SALES_BOTH: 3
} as const

export const accountingMethodTypes = {
  ACCOUNTING_REPORT_METHOD_UNREPORTED: {
    value: 0,
    text: 'Vain kirjanpidosta puuttuvat valitulla aikavälillä'
  },
  ACCOUNTING_REPORT_METHOD_INCLUDE_OLD: {
    value: 1,
    text: 'Kaikki kirjanpidosta puuttuvat kirjaukset aikavälin loppuun asti'
  },
  ACCOUNTING_REPORT_METHOD_OVERRIDE: {
    value: 2,
    text: 'Luo raportti uusiksi aikavälille'
  },
  ACCOUNTING_REPORT_METHOD_OVERRIDE_AND_INCLUDE_OLD: {
    value: 3,
    text: 'Luo raportti uusiksi aikavälille + sisällytä aikaisemmat kirjanpidosta puuttuvat'
  }
}

export const receiptReportMethodTypes = {
  RECEIPT_REPORT_METHOD_UNARCHIVED: {
    value: 0,
    text: 'Vain kirjanpidosta puuttuvat valitulla aikavälillä'
  },
  RECEIPT_REPORT_METHOD_UNARCHIVED_INCLUDE_PREVIOUS_UNARCHIVED: {
    value: 1,
    text: 'Kaikki kirjanpidosta puuttuvat kirjaukset aikavälin loppuun asti'
  },
  RECEIPT_REPORT_METHOD_STANDARD: {
    value: 2,
    text: 'Luo raportti uusiksi aikavälille'
  },
  RECEIPT_REPORT_METHOD_STANDARD_INCLUDE_PREVIOUS_UNARCHIVED: {
    value: 3,
    text: 'Luo raportti uusiksi aikavälille + sisällytä aikaisemmat kirjanpidosta puuttuvat'
  }
}

export const fillOutForms = {
  STAND_REPORT: {
    type: 1,
    name: 'Työtelineen ja sääsuojan käyttöönottotarkastuspöytäkirja'

  },
  TEST_PRESSURE: {
    type: 2,
    name: 'Painekoe, lattialämmitys ja käyttövesipiirit'
  },
  BROAD_INSPECTION_DIARY: {
    type: 4,
    name: 'Laaja tarkastuspöytäkirja: sähköasennuksen käyttöönotto'
  },
  OIL_BURNER_MAINTENANCE: {
    type: 5,
    name: 'Öljypoltinhuolto / mittaus'
  },
  SIMPLE_MEASURE_INSPECTION_DIARY: {
    type: 6,
    name: 'Yksinkertainen tarkastuspöytäkirja: sähköasennuksen käyttöönotto + mittaustulokset'
  },
  FIRE_WORK_DIARY: {
    type: 7,
    name: 'Tulityölupa'
  },
  HEAT_PUMP_INSTALLATION_DIARY: {
    type: 8,
    name: 'Lämpöpumpun asennuspöytäkirja'
  },
  SAFETY_REPORT: {
    type: 9,
    name: 'Turvallisuusraportti'
  },
  WORKSITE_DIARY: {
    type: 10,
    name: 'Työmaapäiväkirja'
  },
  BOMB_SHELTER_DIARY: {
    type: 11,
    name: 'Väestönsuojan vuosihuoltoraportti'
  },
  BOMB_SHELTER_FIX: {
    type: 12,
    name: 'Väestönsuojan tarkastusraportti'
  },
  HEAT_EXCHANGER: {
    type: 13,
    name: 'Lämmönvaihtimen huoltoraportti'
  },
  PRE_HANDOVER_INSPECTION: {
    type: 14,
    name: 'Itselleluovutuslomake'
  },
  BASIC_REPORT: {
    type: 15,
    name: 'Perusraportti'
  },
  MAINTENANCE_REPORT: {
    type: 16,
    name: 'Huoltoraportti'
  },
  WORK_REPORT: {
    type: 17,
    name: 'Työraportti'
  },
  KVV_INSPECTION: {
    type: 18,
    name: 'KVV-työn tarkastusasiakirja'
  },
  MEASURE_DIARY: {
    type: 19,
    name: 'Mittauspöytäkirja/Linjasäädöt'
  },
  AIR_FLOW_MEASURE: {
    type: 20,
    name: 'Ilmavirtojen mittauspöytäkirja'
  },
  CLEANING_DIARY: {
    type: 21,
    name: 'Puhdistuspöytäkirja'
  }
}

export const workRecordProductTypes = {
  WORK_RECORD_TYPE_HOUR: 0,
  WORK_RECORD_TYPE_EXTRAHOUR: 1,
  WORK_RECORD_TYPE_TRAVEL: 2,
  WORK_RECORD_TYPE_OTHER: 3
} as const

export const workRecordProductTypeOptions: TypeMap<typeof workRecordProductTypes, { text: string, value: ValueOf<typeof workRecordProductTypes>, defaultUnit: string, roundingOptions?: Option<number>[] }> = {
  [workRecordProductTypes.WORK_RECORD_TYPE_HOUR]: {
    text: 'Tuntityö',
    value: workRecordProductTypes.WORK_RECORD_TYPE_HOUR,
    defaultUnit: 'h',
    roundingOptions: [
      {
        text: 'Alkava 15 min',
        value: 0.25
      },
      {
        text: 'Alkava 30 min',
        value: 0.5
      },
      {
        text: 'Alkava tunti',
        value: 1
      }
    ]
  },
  [workRecordProductTypes.WORK_RECORD_TYPE_EXTRAHOUR]: {
    text: 'Ylityö',
    value: workRecordProductTypes.WORK_RECORD_TYPE_EXTRAHOUR,
    defaultUnit: 'h',
    roundingOptions: [
      {
        text: 'Alkava 15 min',
        value: 0.25
      },
      {
        text: 'Alkava 30 min',
        value: 0.5
      },
      {
        text: 'Alkava tunti',
        value: 1
      }
    ]
  },
  [workRecordProductTypes.WORK_RECORD_TYPE_TRAVEL]: {
    text: 'Matka',
    value: workRecordProductTypes.WORK_RECORD_TYPE_TRAVEL,
    defaultUnit: 'km',
    roundingOptions: [
      {
        text: 'Alkava 5 km',
        value: 5
      },
      {
        text: 'Alkava 10 km',
        value: 10
      }
    ]
  },
  [workRecordProductTypes.WORK_RECORD_TYPE_OTHER]: {
    text: 'Muu',
    value: workRecordProductTypes.WORK_RECORD_TYPE_OTHER,
    defaultUnit: ''
  }
}

export const machinePriceRoundingOptions = [
  {
    text: 'Ei pyöristystä',
    value: 0
  },
  {
    text: 'Seuraava 1/4',
    value: 0.25
  },
  {
    text: 'Seuraava 1/2',
    value: 0.5
  },
  {
    text: 'Seuraava kokonainen',
    value: 1
  }
]

export const machinePricingRuleTimeTypeUnitsMap = {
  tunti: 'hour',
  päivä: 'day',
  viikko: 'week',
  kuukausi: 'month'
} as const

export const machinePricingRuleWorkRecordProductValueTypes = {
  AMOUNT_PER_UNIT: 0,
  AMOUNT: 1
} as const

export const machinePricingRuleWorkRecordProductValueOptions: Option<ValueOf<typeof machinePricingRuleWorkRecordProductValueTypes>>[] = [
  {
    text: 'kpl / määrä',
    value: machinePricingRuleWorkRecordProductValueTypes.AMOUNT_PER_UNIT
  },
  {
    text: 'kpl / kirjaus',
    value: machinePricingRuleWorkRecordProductValueTypes.AMOUNT
  }
]

export const machinePricingRuleTypes = {
  AMOUNT: 'amount',
  TIMER: 'timer',
  TIME: 'time'
} as const

export const machinePricingRuleTimeTypes = [machinePricingRuleTypes.TIME, machinePricingRuleTypes.TIMER]

export const machinePricingRuleTimeTypeUnitOptions: Option<string>[] = [
  {
    text: 'tunti',
    value: 'tunti'
  },
  {
    text: 'päivä',
    value: 'päivä'
  },
  {
    text: 'viikko',
    value: 'viikko'
  },
  {
    text: 'kuukausi',
    value: 'kuukausi'
  }
]

export const machinePricingRuleTypeOptions: (Option<ValueOf<typeof machinePricingRuleTypes>> | { info: string })[] = [
  {
    value: machinePricingRuleTypes.AMOUNT,
    text: 'Määrä',
    info: 'Kirjauksille asetettavissa vain määrä. Ei näytetä aloitus- tai lopetusaikavalintoja.'
  },
  {
    value: machinePricingRuleTypes.TIMER,
    text: 'Ajastin',
    info: 'Kirjausten määrä lasketaan aloitus- sekä lopetusajasta. Kirjaus voidaan aloittaa jättämällä loppupäivä sekä aika tyhjäksi'
  },
  {
    value: machinePricingRuleTypes.TIME,
    text: 'Aika',
    info: 'Kirjausten määrä lasketaan aloitus- sekä lopetusajasta. Kirjausta ei voida erikseen käynnistää.'
  }
]

export const machineSystemTypes = {
  MACHINE: 'machine',
  RESOURCE: 'resource',
  INTERNAL: 'internal'
} as const

export const machineSystemTypeOptions: OptionWithColor<ValueOf<typeof machineSystemTypes>>[] = [
  {
    value: machineSystemTypes.MACHINE,
    text: 'Kone',
    color: 'primary'
  },
  {
    value: machineSystemTypes.RESOURCE,
    text: 'Kulutustavara',
    color: 'secondary'
  },
  {
    value: machineSystemTypes.INTERNAL,
    text: 'Sisäinen',
    color: 'warning'
  }
]

const paymentTerms = [7, 10, 14, 21, 30, 45, 60, 90, 0] as const
export const paymentTermOptions: Option<ValueOf<typeof paymentTerms>>[] = paymentTerms.map(paymentTerm => ({ value: paymentTerm, text: `${paymentTerm} pv` }))

export const industries: Option<number>[] = [
  { text: 'Sähkö', value: 0 },
  { text: 'LV', value: 1 },
  { text: 'IV', value: 2 },
  { text: 'Kylmä', value: 3 },
  { text: 'Rakennus', value: 4 },
  { text: 'Maarakennus', value: 5 },
  { text: 'Vesirakennus', value: 6 },
  { text: 'Urakointi', value: 7 },
  { text: 'Automaatio', value: 8 },
  { text: 'Sprinkleri', value: 9 },
  { text: 'Puhtaanapito', value: 10 },
  { text: 'IT', value: 11 },
  { text: 'Kuljetusala', value: 12 },
  { text: 'Teollisuus', value: 13 },
  { text: 'Joku muu', value: 999 }
]

export const paymentMethodOptions: Option<number>[] = [
  { value: 1, text: 'Käteinen' },
  { value: 2, text: 'Luottokortti' },
  { value: 3, text: 'Pankkikortti' },
  { value: 4, text: 'Muu' }
]

export const workRecordProductSalaryTypeValueTypes = {
  WORK_RECORD_PRODUCT_SALARY_TYPE_VALUE_TYPE_PERCENT: 0,
  WORK_RECORD_PRODUCT_SALARY_TYPE_VALUE_TYPE_AMOUNT: 1,
  WORK_RECORD_PRODUCT_SALARY_TYPE_VALUE_TYPE_AMOUNT_PER_UNIT: 2
} as const

export const ADMINISTRATIVE_SYSTEM_CUSTOMER_ID = 1
export const EVELIA_SYSTEM_CUSTOMER_ID = 200

export const projectSubItems = {
  PROJECTS_WORK: 'work',
  PROJECTS_INVOICES: 'invoices',
  PROJECTS_INBOUND_INVOICES: 'inbound_invoices',
  PROJECTS_BUDGET: 'budget',
  PROJECTS_BUDGET_RECURSIVE: 'recursive',
  PROJECTS_BUDGET_BY_DENOMINATION: 'by_denomination'
} as const

export const projectApiActions = {
  INSTALMENTS: 'instalments',
  INSTALMENT_TEMPLATES: 'instalment_templates'
} as const

export const reminderInvoiceSenders = {
  SELF: 'self',
  MAVENTA: 'maventa',
  MAVENTA_AUTO: 'maventa_auto'
  // VISMA: 'visma'
} as const

export const reminderInvoiceSenderOptions: (Option<ValueOf<typeof reminderInvoiceSenders>> | { info: string })[] = [
  { value: reminderInvoiceSenders.SELF, text: 'Lähetän itse', info: 'Muistutuslaskut valitaan itse. Laskut jäävät avoimiksi.' },
  { value: reminderInvoiceSenders.MAVENTA, text: 'Maventa Manuaalinen', info: 'Muistutuslaskut valitaan itse. Laskut lähetetään Mavennan verkkolaskutuspalveluun. Asetus edellyttää Maventa-tunnuksia "Liittymät"-välilehdellä.' },
  { value: reminderInvoiceSenders.MAVENTA_AUTO, text: 'Maventa Automaattinen', info: 'Muistutuslaskut lähetetään automaattisesti Mavennan verkkolaskutuspalvelun kautta päivittäin. Asetus edellyttää Maventa-tunnuksia "Liittymät"-välilehdellä.' }
  // { value: reminderInvoiceSenders.VISMA, text: 'Visma' }
]

export const cashReceiptPrintingMethods = {
  PRINT: 'print',
  SEND_EMAIL: 'sendEmail'
} as const

export const cashReceiptPaymentMethods = {
  DEBIT_CARD: 'debitCard',
  CREDIT_CARD: 'creditCard',
  CASH: 'cash',
  OTHER: 'other',
  CHANGE_TO_WORK: 'changeToWork'
} as const

export const cashReceiptPaymentMethodsSimplified = {
  CARD: 'card',
  CASH: 'cash',
  OTHER: 'other',
  CHANGE_TO_WORK: 'changeToWork'
} as const

interface OptionWithClassName extends Omit<OptionWithColor<never>, 'value'> {
  className: string
}

interface LabelOptionWithClassName extends Omit<OptionWithClassName, 'text'> {
  label: string
}

export const cashReceiptPaymentMap: TypeMap<typeof cashReceiptPaymentMethods, OptionWithClassName> = {
  [cashReceiptPaymentMethods.DEBIT_CARD]: { text: 'Pankkikortti', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethods.CREDIT_CARD]: { text: 'Luottokortti', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethods.CASH]: { text: 'Käteinen', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethods.OTHER]: { text: 'Muu maksutapa', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethods.CHANGE_TO_WORK]: { text: 'Muunna työksi (laskuta)', color: 'success', className: 'me-2' }
}

export const cashReceiptPaymentMapSimplified: TypeMap<typeof cashReceiptPaymentMethodsSimplified, OptionWithClassName> = {
  [cashReceiptPaymentMethodsSimplified.CARD]: { text: 'Kortti', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethodsSimplified.CASH]: { text: 'Käteinen', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethodsSimplified.OTHER]: { text: 'Muu maksutapa', color: 'success', className: 'me-2' },
  [cashReceiptPaymentMethodsSimplified.CHANGE_TO_WORK]: { text: 'Muunna työksi (laskuta)', color: 'success', className: 'me-2' }
}

export const cashReceiptPrintingMap: TypeMap<typeof cashReceiptPrintingMethods, LabelOptionWithClassName> = {
  [cashReceiptPrintingMethods.PRINT]: { label: 'Tulostus', color: 'success', className: 'me-2' },
  [cashReceiptPrintingMethods.SEND_EMAIL]: { label: 'Sähköposti', color: 'success', className: 'me-2' }
}

export const offerVatTypeOptions: Option<ValueOf<typeof offerVatTypes>>[] = [
  { value: offerVatTypes.OFFER_VAT_TYPE_DEFAULT, text: 'Normaali alv' },
  { value: offerVatTypes.OFFER_VAT_TYPE_VATLESS, text: 'EU-myynti (alv 0%)' },
  { value: offerVatTypes.OFFER_VAT_TYPE_REVERSED, text: 'Käänteinen alv' }
]

export const offerPricingTypes = {
  OFFER_PRICING_TYPE_SUPPLIER_ORDER: 0,
  OFFER_PRICING_TYPE_CHEAPEST: 1,
  OFFER_PRICING_TYPE_SELECTED: 2
} as const

export const offerPricingTypeOptions: Option<ValueOf<typeof offerPricingTypes>>[] = [
  { value: offerPricingTypes.OFFER_PRICING_TYPE_SUPPLIER_ORDER, text: 'Tukkujärjestys' },
  { value: offerPricingTypes.OFFER_PRICING_TYPE_CHEAPEST, text: 'Halvin ostohinta' },
  { value: offerPricingTypes.OFFER_PRICING_TYPE_SELECTED, text: 'Valitut tukut' }
]

export const productPurchasePricingTypes = {
  PRODUCT_PURCHASE_PRICING_TYPE_CHEAPEST: 1
} as const

export const inboundInvoiceRowTypes = {
  INBOUND_INVOICE_ROW_TYPE_DEFAULT: 'default',
  INBOUND_INVOICE_ROW_TYPE_SUBTOTAL: 'subtotal',
  INBOUND_INVOICE_ROW_TYPE_HEADER: 'header',
  INBOUND_INVOICE_ROW_TYPE_INFO: 'info'
} as const

export const factoringOptions: Option<ValueOf<typeof factoringProviderIds>>[] = [
  { text: 'Ei käytössä', value: factoringProviderIds.NOT_ENABLED },
  { text: 'Noja Rahoitus', value: factoringProviderIds.FACTORING_NOJA },
  { text: 'Certum', value: factoringProviderIds.FACTORING_CERTUM }
]

export const cashReceiptRowTypes = {
  CASH_RECEIPT_ROW_TYPE_PRODUCT: 'product',
  CASH_RECEIPT_ROW_TYPE_WORK_RECORD: 'workrecord',
  CASH_RECEIPT_ROW_TYPE_COMMENT: 'comment',
  CASH_RECEIPT_ROW_TYPE_DISCOUNT: 'discount',
  CASH_RECEIPT_ROW_TYPE_ROUNDING: 'rounding'
} as const

export const offerPdfSumTypes = {
  SUMS_VATLESS: 1,
  SUMS_VAT: 2
} as const

export const projectAdditionalEmployeeTypeOptions: Option<ValueOf<typeof projectAdditionalEmployeeTypes>>[] = [
  { value: projectAdditionalEmployeeTypes.PROJECT_ADDITIONAL_EMPLOYEE_TYPE_NORMAL_EMPLOYEE, text: 'Alihankkijan työntekijä' },
  { value: projectAdditionalEmployeeTypes.PROJECT_ADDITIONAL_EMPLOYEE_TYPE_RENTAL_EMPLOYEE, text: 'Vuokratyöntekijä' },
  { value: projectAdditionalEmployeeTypes.PROJECT_ADDITIONAL_EMPLOYEE_TYPE_INDEPENDENT_EMPLOYEE, text: 'Itsenäinen ammatinharjoittaja' }
]

export const employeeLicenceReportTypes = {
  CSV: 'csv_report',
  PDF_BY_EMPLOYEE: 'pdf_report_by_employee',
  PDF_BY_TYPE: 'pdf_report_by_type'
} as const

export const serviceLevels = {
  SERVICE_LEVEL_PRO: 'pro',
  SERVICE_LEVEL_PLUS: 'plus',
  SERVICE_LEVEL_FULL: 'full',
  SERVICE_LEVEL_INVOICING: 'invoicing'
} as const

export const serviceLevelValues: TypeMap<typeof serviceLevels, number> = {
  [serviceLevels.SERVICE_LEVEL_PRO]: 300,
  [serviceLevels.SERVICE_LEVEL_PLUS]: 200,
  [serviceLevels.SERVICE_LEVEL_FULL]: 100,
  [serviceLevels.SERVICE_LEVEL_INVOICING]: 10
}

const serviceLevelOptions: OptionWithColor<ValueOf<typeof serviceLevels>>[] = [
  {
    text: 'Pro',
    value: serviceLevels.SERVICE_LEVEL_PRO,
    color: 'success'
  },
  {
    text: 'Plus',
    value: serviceLevels.SERVICE_LEVEL_PLUS,
    color: 'success'
  },
  {
    text: 'Täysversio',
    value: serviceLevels.SERVICE_LEVEL_FULL,
    color: 'success'
  },
  {
    text: 'Laskutusversio',
    value: serviceLevels.SERVICE_LEVEL_INVOICING,
    color: 'warning'
  }
]

export const serviceLevelMap = serviceLevelOptions.reduce((acc, value) => {
  acc[value.value] = value
  return acc
}, {})

export const evelinBillingTypes = {
  EVELIN_BILLING_TYPE_INVOICE: 'lasku',
  EVELIN_BILLING_TYPE_PAYTRAIL: 'paytrail'
} as const

export const projectMonetaryExtraDenominationExpenseTypes = {
  EXPENSE: 'expense',
  SALARY: 'salary',
  SUBCONTRACTING: 'subcontracting'
} as const

export const projectMonetaryExtraExpenseTypes = {
  ...projectMonetaryExtraDenominationExpenseTypes,
  INCOME: 'income'
} as const

export const projectDenominationExtraExpenseTypes = {
  ...projectMonetaryExtraDenominationExpenseTypes,
  HOUR: 'hour'
} as const

export const projectExtraExpenseTypes = {
  ...projectMonetaryExtraExpenseTypes,
  ...projectDenominationExtraExpenseTypes
} as const

export const projectBudgetFieldMap = {
  [projectExtraExpenseTypes.INCOME]: {
    label: 'Myyntiarvio',
    field: 'estimatedSales',
    totalSumField: 'totalInvoicePriceSum',
    isVatless: true,
    unit: '€'
  },
  [projectExtraExpenseTypes.EXPENSE]: {
    label: 'Materiaalikustannusarvio',
    field: 'estimatedCost',
    totalSumField: 'totalExpensesSum',
    isVatless: true,
    unit: '€'
  },
  [projectExtraExpenseTypes.HOUR]: {
    label: 'Tuntiarvio',
    field: 'estimatedHours',
    totalSumField: 'totalBasicHours',
    isVatless: false,
    unit: 'h'
  },
  [projectExtraExpenseTypes.SALARY]: {
    label: 'Palkkakustannusarvio',
    field: 'estimatedSalaryPrices',
    totalSumField: 'totalWorkRecordExpensesSum',
    isVatless: false,
    unit: '€'
  },
  [projectExtraExpenseTypes.SUBCONTRACTING]: {
    label: 'Alihankinta-arvio',
    field: 'estimatedSubcontracting',
    totalSumField: 'totalSubcontractingSum',
    isVatless: true,
    unit: '€'
  }
} as const

export const projectExtraExpenseTypesMap: TypeMap<typeof projectExtraExpenseTypes, TextOption> = {
  [projectExtraExpenseTypes.INCOME]: { text: 'Tulo' },
  [projectExtraExpenseTypes.EXPENSE]: { text: 'Kulu' },
  [projectExtraExpenseTypes.SALARY]: { text: 'Palkkakustannus' },
  [projectExtraExpenseTypes.HOUR]: { text: 'Tunti' },
  [projectExtraExpenseTypes.SUBCONTRACTING]: { text: 'Alihankinta' }
} as const

export const projectTypeTypes = {
  PROJECT_TYPE_TYPE_CONTRACT: 'contract',
  PROJECT_TYPE_TYPE_ADDITIONAL: 'additional',
  PROJECT_TYPE_TYPE_EXPENSE: 'expense'
} as const

export const projectTypeTypesMap: TypeMap<typeof projectTypeTypes, TextOption> = {
  [projectTypeTypes.PROJECT_TYPE_TYPE_CONTRACT]: { text: 'Urakka' },
  [projectTypeTypes.PROJECT_TYPE_TYPE_ADDITIONAL]: { text: 'Lisätyö' },
  [projectTypeTypes.PROJECT_TYPE_TYPE_EXPENSE]: { text: 'Kulu' }
}

export const workRecordProductPatchActions = {
  TOGGLE_OPEN: 'toggle_open'
} as const

export const productPatchActions = {
  TOGGLE_OPEN: 'toggle_open',
  RESTORE: 'restore'
} as const

export const offerCopyMethods = {
  COPY_OFFER: 1,
  COPY_POSTS: 2,
  COPY_ROWS: 4
} as const

export const statusStateMap: TypeMap<typeof genericSendStatuses, Omit<OptionWithColor<never>, 'value'>> = {
  [genericSendStatuses.WAITING]: {
    text: 'Odottaa',
    color: 'warning'
  },
  [genericSendStatuses.COMPLETED]: {
    text: 'Toimitettu',
    color: 'success'
  },
  [genericSendStatuses.ERROR]: {
    text: 'Virhe',
    color: 'danger'
  }
}

export const smsMessageTemplateOptions: Option<string>[] = [{ value: 'work', text: 'Työviesti' }, { value: 'supplyOffer', text: 'Tarjousviesti' }]

const smsMessageCommonKeywords = {
  systemCustomerName: 'yrityksen nimi',
  systemCustomerEmail: 'yrityksen email',
  systemCustomerPhoneNumber: 'yrityksen puh',
  userName: 'oma nimi',
  userEmail: 'oma email',
  userPhoneNumber: 'oma puh'
} as const

export const smsMessageTemplateKeywords = {
  work: {
    name: 'työ nimi',
    workNumber: 'työ nro',
    startDate: 'aloitus pvm',
    description: 'työselite',
    supervisorPhoneNumber: 'työnjohdon puh',
    customerName: 'asiakkaan nimi',
    customerAddress: 'asiakkaan osoite',
    customerPhoneNumber: 'asiakkaan puh',
    targetName: 'kohteen nimi',
    targetAddress: 'kohteen osoite',
    contactName: 'tilaajan nimi',
    contactPhoneNumber: 'tilaajan puh',
    ...smsMessageCommonKeywords
  },
  supplyOffer: {
    name: 'tarviketarjouksen nimi',
    supplyOfferNumber: 'tarviketarjouksen nro',
    description: 'kuvaus',
    supervisorPhoneNumber: 'työnjohdon puh',
    customerName: 'asiakkaan nimi',
    customerAddress: 'asiakkaan osoite',
    customerPhoneNumber: 'asiakkaan puh',
    targetName: 'kohteen nimi',
    targetAddress: 'kohteen osoite',
    ...smsMessageCommonKeywords
  }
} as const

export const smsMessagePhoneNumbers = {
  work: {
    customer: { type: 'asiakkaan puh', name: 'Asiakas' },
    contact: { type: 'tilaajan puh', name: 'Tilaaja' },
    supervisor: { type: 'työnjohdon puh', name: 'Työnjohtaja' }
  },
  supplyOffer: {
    customer: { type: 'asiakkaan puh', name: 'Asiakas' },
    supervisor: { type: 'työnjohdon puh', name: 'Työnjohtaja' }
  }
} as const

export const offerPatchActions = {
  SET_OFFER_CUSTOM_STATE: 'set_offer_custom_state',
  CREATE_PROJECT_FROM_OFFER: 'create_project_from_offer',
  CONNECT_OFFER_TO_PROJECT: 'connect_offer_to_project'
} as const

export const supplyOfferPatchActions = {
  MARK_REVERSED_VAT: 'mark_reversed_vat',
  MARK_EU_SALES: 'mark_eu_sales',
  SET_SUPPLY_OFFER_CUSTOM_STATE: 'set_supply_offer_custom_state',
  CREATE_WORK_FROM_SUPPLY_OFFER: 'create_work_from_supply_offer'
} as const

export const purchaseOrderPatchActions = {
  SET_PURCHASE_ORDER_STATE: 'set_purchase_order_state'
} as const

export const customerRegisterationStatuses = {
  UNREGISTERED: 'unregistered'
} as const

export const invoiceOverrideReceiverOptions = {
  TARGET: 'target',
  CUSTOMER: 'customer'
} as const

export const schedulerLicenseKey = '0588610082-fcs-1713492887'

export const productGroupLevel = {
  LEVEL1: 'level1',
  LEVEL2: 'level2'
} as const

export const storageKeys = {
  SEND_WORK_RECORD_DISAPPROVAL_EMAIL: 'workRecordSendDisapprovalEmail',
  SIDE_DRAWER_ACCESS: 'sideDrawerAccess',
  CALENDAR_ITEM_COLOR_SPECIFIER: 'calendarItemColorSpecifier',
  CALENDAR_SHOW_EMPLOYEE_ROLES: 'calendarShowEmployeeRoles',
  CALENDAR_SHOW_MATCHING_ROLES: 'calendarShowMatchingRoles',
  ACTIVITY_STREAM_SHOW_ONLY_UNCONFIMED: 'activity_stream_show_only_unconfirmed',
  DATA_TABLE_SIZING_WORK_ROW: 'data_table_sizing_work_row',
  DATA_TABLE_SORTING_CONTACT: 'data_table_sorting_contact',
  DATA_TABLE_VISIBILITY_WORK_ROW: 'data_table_visibility_work_row',
  WORK_ROW_TABLE_IS_SINGLE_COLUMN: 'work_row_table_is_single_column',
  WORK_ROW_TABLE_INCLUDE_PREVENT_INVOICING: 'work_row_table_include_prevent_invoicing',
  DATA_TABLE_SIZING_FILES: 'data_table_sizing_files',
  DATA_TABLE_SORTING_FILES: 'data_table_sorting_files',
  DATA_TREE_TABLE_SIZING_FILES: 'data_tree_table_sizing_files',
  DATA_TREE_TABLE_SORTING_FILES: 'data_tree_table_sorting_files',
  DATA_TABLE_PRODUCT_SALES: 'product_sales',
  DATA_TABLE_VISIBILITY_PRODUCT_SALES: 'data_table_visibility_product_sales',
  DATA_TABLE_QUICK_PRODUCT: 'quick_product',
  DATA_TABLE_VISIBILITY_DENOMINATION_BUDGET: 'data_table_visibility_denomination_budget',
  WORK_RECORD_OVERVIEW_TABLE_APPROVAL_MODE: 'work_record_overview_table_approval_mode',
  DATA_TABLE_VISIBILITY_OFFER_POST_ROW: 'data_table_visibility_order_post_row',
  WORK_FORM_TABLE: 'work_form_table',
  WORK_MAP_FILTERS: 'work_map_filters',
  DATA_TABLE_VISIBILITY_PURCHASE_ORDER_ROW: 'data_table_visibility_purchase_order_row',
  CALENDAR_SHOW_ONLY_WORKING_TIME: 'calendar_show_only_working_time'
} as const

export const barcodeErrors = {
  TOO_MANY_RESULTS: 'tooManyResults',
  NO_RESULTS: 'noResults',
  OTHER_ERROR: 'otherError'
} as const

export const offerPostPatchActions = {
  SET_POST_COUNT_FACTOR: 'set_post_count_factor',
  SET_OFFER_POST_STATE: 'set_offer_post_state'
} as const

export const offerPostRowActions = {
  REPRICE: 'reprice'
} as const

export const offerPostRowImportErrors = {
  MULTIPLE_INSTALLATIONS: 'multipleInstallations',
  MISSING_PRODUCT: 'missingProduct',
  MISSING_PACKET_OR_INSTALLATION: 'missingPacketOrInstallation'
} as const

export const extraTimeTimerecordAccumulationTypes = [
  timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_FLEXITIME,
  timeRecordAccumulationTypes.TIME_RECORD_ACCUMULATION_TYPE_OVERTIME
] as ValueOf<typeof timeRecordAccumulationTypes>[]

export const timeRecordSystemTypesOptions: Option<ValueOf<typeof timeRecordSystemTypes>>[] = [
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_AWAY, text: 'Poissa' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_CUSTOMER, text: 'Asiakkaalla' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_EXTRA_LEAVE, text: 'Pekkasvapaa' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_LUNCH, text: 'Lounaalla' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_WORK_TIME, text: 'Työaikaa' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE, text: 'Sairasloma' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE_OCCUPATIONAL_ACCIDENT, text: 'Sairasloma (työtapaturma)' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_SICK_LEAVE_CHILD_SICK, text: 'Sairasloma (lapsen sairaus)' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_VACATION_WITH_SALARY, text: 'Muu palkallinen vapaa' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_TRAINING, text: 'Koulutus' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_STAND_BY, text: 'Varallaolo' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_ON_CALL, text: 'Päivystys' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_TRAVEL_TIME, text: 'Matka-aika' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_VACATION_WITHOUT_SALARY, text: 'Muu palkaton vapaa' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_ANNUAL_LEAVE, text: 'Vuosiloma' },
  { value: timeRecordSystemTypes.TIME_RECORD_SYSTEM_TYPE_FLEXITIME, text: 'Liukumasaldo' }
]

export const offerLogoAlignment = {
  LEFT: 'left',
  CENTER: 'center'
} as const

export const logoTypes = {
  LOGO_DEFAULT: 'companyLogo',
  LOGO_OFFER: 'offerLogo',
  LOGO_SUPPLY_OFFER: 'supplyOfferLogo',
  LOGO_FORM_TYPE: 'formTypeLogo'
} as const

export const notificationSubTypes = {
  TICKET: 'ticket'
} as const

export const ticketStateStates = {
  NEW: 'new',
  IN_PROGRESS: 'inProgress',
  COMPLETE: 'complete'
} as const

export const ticketStateStatesMap: TypeMap<typeof ticketStateStates, TextOption> = {
  [ticketStateStates.NEW]: { text: 'Uusi' },
  [ticketStateStates.IN_PROGRESS]: { text: 'Kesken' },
  [ticketStateStates.COMPLETE]: { text: 'Valmis' }
}

export const inboundMessageStates = {
  NEW: 0,
  IN_PROGRESS: 1,
  COMPLETE: 2,
  IGNORED: 3
} as const

export const inboundMessageStatesMap: TypeMap<typeof inboundMessageStates, { text: string, color: string }> = {
  [inboundMessageStates.NEW]: { text: 'Uusi', color: 'warning' },
  [inboundMessageStates.IN_PROGRESS]: { text: 'Kesken', color: 'info' },
  [inboundMessageStates.COMPLETE]: { text: 'Valmis', color: 'success' },
  [inboundMessageStates.IGNORED]: { text: 'Ei käsitellä', color: 'error' }
}

export const inboundMessageStateOptions = Object.entries(inboundMessageStatesMap)
  .map(([key, { text }]) => ({
    id: Number(key), name: text
  }))

export const ticketTypes = {
  TICKET: 'ticket',
  TICKET_TEMPLATE: 'ticket_template'
} as const

export const recurringInvoiceSendType = {
  NO_INVOICE: 'no_invoice',
  CREATE: 'create',
  CREATE_AND_CHARGE: 'create_and_charge'
} as const

export const recurringInvoiceSendTypesMap: TypeMap<typeof recurringInvoiceSendType, TextOption> = {
  [recurringInvoiceSendType.NO_INVOICE]: { text: 'Ei laskua' },
  [recurringInvoiceSendType.CREATE]: { text: 'Luo lasku avoimeksi' },
  [recurringInvoiceSendType.CREATE_AND_CHARGE]: { text: 'Luo lasku ja lähetä' }
}

export const invoiceReceiverMap: TypeMap<typeof invoiceOverrideReceiverOptions, TextOption> = {
  [invoiceOverrideReceiverOptions.CUSTOMER]: { text: 'Asiakas' },
  [invoiceOverrideReceiverOptions.TARGET]: { text: 'Kohde' }
}

export const denominationTypes = {
  DENOMINATION_TYPE_WORK: 'work',
  DENOMINATION_TYPE_WORK_RECORD: 'work_record'
} as const

export const denominationTypeMap: TypeMap<typeof denominationTypes, TextOption> = {
  [denominationTypes.DENOMINATION_TYPE_WORK]: { text: 'Rakenne' },
  [denominationTypes.DENOMINATION_TYPE_WORK_RECORD]: { text: 'Suorite' }
}
export const formTypeTypes = {
  WORK: 'work',
  INVOICE: 'invoice'
} as const

export const vatTypes = {
  reversedVat: 'reversedVat',
  euSales: 'euSales',
  normal: null
} as const

export const vatTypeTitles: Record<NonNullable<ValueOf<typeof vatTypes>> | 'default', string> = {
  [vatTypes.reversedVat]: 'Käänteinen arvonlisävero',
  [vatTypes.euSales]: 'Veroton EU-myynti (yhteisömyynti)',
  default: 'Normaali arvonlisävero'
}

export const vatTypeOptions: Option<ValueOf<typeof vatTypes>>[] = [
  { value: null, text: vatTypeTitles.default },
  { value: vatTypes.reversedVat, text: vatTypeTitles.reversedVat },
  { value: vatTypes.euSales, text: vatTypeTitles.euSales }
]

export const offerVatTypeToWorkVatTypeMap: TypeMap<typeof offerVatTypes, ValueOf<typeof vatTypes>> = {
  [offerVatTypes.OFFER_VAT_TYPE_DEFAULT]: vatTypes.normal,
  [offerVatTypes.OFFER_VAT_TYPE_VATLESS]: vatTypes.euSales,
  [offerVatTypes.OFFER_VAT_TYPE_REVERSED]: vatTypes.reversedVat
}

export const vatRateIdentifiers = {
  GENERIC: 'generic',
  REDUCED1: 'reduced1',
  REDUCED2: 'reduced2',
  VATLESS: 'vatless',
  REVERSED: 'reversed'
} as const

export const inboundInvoicePatchActions = {
  MARK_REVERSED_VAT: 'mark_reversed_vat',
  CHANGE_ACCOUNTING_SOURCE: 'accounting_source'
} as const

export const authErrorMessages = {
  consent_required: 'Käyttäjä peruutti tunnistautumisen'
} as const

export const vacationAccumulationRightRuleOptionsMap: TypeMap<typeof vacationAccumulationRightRules, string> = {
  [vacationAccumulationRightRules.VACATION_ACCUMULATION_DAYS]: '14 pv/kk',
  [vacationAccumulationRightRules.VACATION_ACCUMULATION_HOURS]: '35 h/kk',
  [vacationAccumulationRightRules.NO_VACATION_ACCUMULATION]: 'Ei lomien kertymistä'
}
