import { lazy } from 'react'
import {
  faBoxOpen,
  faBullhorn,
  faBusinessTime,
  faCalendarAlt,
  faCalendarPlus,
  faCashRegister,
  faChartLine,
  faCogs,
  faCubes,
  faEnvelope,
  faEuroSign,
  faFile,
  faHammer,
  faHandshake,
  faProjectDiagram,
  faReceipt,
  faShoppingCart,
  faThLarge,
  faTicketAlt,
  faUserCog,
  faUserEdit,
  faUserTie,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { faCircleEuro, faRectangleList } from '@fortawesome/pro-solid-svg-icons'
import { UnknownRecord, ValueOf } from 'type-fest'

import FileInvoiceEur from '../../components/Icons/FileInvoiceEur'
import HandHoldingEur from '../../components/Icons/HandHoldingEur'
import InboundMessageNavBadge from '../../components/InboundMessages/InboundMessageNavBadge'
import { systemMessageTitlesCapitalized } from '../../components/SystemMessage/systemMessageTitles'
import { ticketTitlesCapitalized } from '../../components/Tickets/ticketTitles'
import constants, { routePaths } from '../../constants'
import WorkRecordNavBadge from '../WorkRecordPage/WorkRecordNavBadge'

const AsyncHome = lazy(() => import('../Home/Home'))
const AsyncTargetPage = lazy(() => import('../TargetPage'))
const AsyncWorkPage = lazy(() => import('../WorkPage'))
const AsyncCustomerPage = lazy(() => import('../CustomerPage'))
const AsyncContactPage = lazy(() => import('../ContactPage'))
const AsyncProductPage = lazy(() => import('../ProductPage'))
const AsyncInvoicePage = lazy(() => import('../InvoicePage'))
const AsyncInboundInvoicePage = lazy(() => import('../InboundInvoicePage'))
const AsyncReportPage = lazy(() => import('../ReportPage'))
const AsyncFilePage = lazy(() => import('../FilePage'))
const AsyncReceiverPage = lazy(() => import('../ReceiverPage'))
const AsyncSettingsPage = lazy(() => import('../SettingsPage'))
const AsyncSettingsWizardPage = lazy(() => import('../SettingsWizardPage'))
const AsyncSystemMessagePage = lazy(() => import('../SystemMessagePage'))
const AsyncCreditActionsPage = lazy(() => import('../CreditActionPage'))
const AsyncFinancePage = lazy(() => import('../FinancePage'))
const AsyncInvitationPage = lazy(() => import('../InvitationPage'))
const AsyncWorkRecordPage = lazy(() => import('../WorkRecordPage'))
const AsyncReceiptPage = lazy(() => import('../ReceiptPage'))
const AsyncCalendarPage = lazy(() => import('../CalendarPage'))
const AsyncProjectPage = lazy(() => import('../ProjectPage'))
const AsyncCashReceiptPage = lazy(() => import('../CashReceiptPage'))
const AsyncOfferPage = lazy(() => import('../OfferPage'))
const AsyncUserPage = lazy(() => import('../UserPage'))
const AsyncSupplyOfferPage = lazy(() => import('../SupplyOfferPage'))
const AsyncTicketPage = lazy(() => import('../TicketPage'))
const AsyncTimeRecordPage = lazy(() => import('../TimeRecordPage'))
const AsyncInboundMessagePage = lazy(() => import('../InboundMessagePage'))
const AsyncFormTemplates = lazy(() => import('../FormTemplatePage'))
const AsyncCreditActions = lazy(() => import('../CreditActionPage'))
const AsyncSalaryPage = lazy(() => import('../SalaryPage/SalaryPage'))
const AsyncPurchaseOrderPage = lazy(() => import('../PurchaseOrderPage'))

interface RoutesSpec<T extends React.ComponentType = React.ComponentType> {
  text: string
  path: ValueOf<typeof routePaths>
  exact: boolean
  sidebarEnabled: boolean
  icon?: IconDefinition | null
  renderComponent: (renderProps: UnknownRecord) => React.ReactElement
  featureKey?: ValueOf<typeof constants.actionKeys>
  badgeComponent?: React.ComponentType
  IconComponent?: T
  iconProps?: React.ComponentProps<T>
}

const routes: RoutesSpec[] = [
  {
    text: 'Etusivu',
    path: routePaths.PATH_FRONTPAGE,
    exact: true,
    sidebarEnabled: false,
    icon: null,
    renderComponent: renderProps => <AsyncHome {...renderProps} />
  },
  {
    text: 'Työt & Lähetteet',
    path: routePaths.PATH_WORK,
    exact: false,
    sidebarEnabled: true,
    icon: faThLarge,
    featureKey: constants.actionKeys.WORK,
    renderComponent: renderProps => <AsyncWorkPage {...renderProps} />
  },
  {
    text: 'Kohteet',
    path: routePaths.PATH_TARGETS,
    exact: false,
    sidebarEnabled: true,
    icon: faCubes,
    featureKey: constants.actionKeys.TARGETS,
    renderComponent: renderProps => <AsyncTargetPage {...renderProps} />
  },
  {
    text: 'Asiakkaat',
    path: routePaths.PATH_CUSTOMERS,
    exact: false,
    sidebarEnabled: true,
    icon: faUserTie,
    featureKey: constants.actionKeys.CUSTOMERS,
    renderComponent: renderProps => <AsyncCustomerPage {...renderProps} />
  },
  {
    text: 'Tuotteet',
    path: routePaths.PATH_PRODUCTS,
    exact: false,
    sidebarEnabled: true,
    icon: faBoxOpen,
    featureKey: constants.actionKeys.PRODUCTS,
    renderComponent: renderProps => <AsyncProductPage {...renderProps} />
  },
  {
    text: 'Projektit',
    path: routePaths.PATH_PROJECTS,
    exact: false,
    sidebarEnabled: true,
    icon: faProjectDiagram,
    featureKey: constants.actionKeys.PROJECTS,
    renderComponent: renderProps => <AsyncProjectPage {...renderProps} />
  },
  {
    text: 'Henkilöt',
    path: routePaths.PATH_CONTACTS,
    exact: false,
    sidebarEnabled: true,
    icon: faUserCog,
    featureKey: constants.actionKeys.CONTACTS,
    renderComponent: renderProps => <AsyncContactPage {...renderProps} />
  },
  {
    text: 'Tuntilappu',
    path: routePaths.PATH_WORK_RECORDS,
    exact: false,
    sidebarEnabled: true,
    icon: faBusinessTime,
    featureKey: constants.actionKeys.WORK_RECORDS,
    renderComponent: renderProps => <AsyncWorkRecordPage {...renderProps} />,
    badgeComponent: WorkRecordNavBadge
  },
  {
    text: 'Laskutus',
    path: routePaths.PATH_INVOICES,
    exact: false,
    sidebarEnabled: true,
    IconComponent: FileInvoiceEur,
    featureKey: constants.actionKeys.INVOICES,
    renderComponent: renderProps => <AsyncInvoicePage {...renderProps} />
  },
  {
    text: 'Ostot',
    path: routePaths.PATH_INBOUND_INVOICES,
    exact: false,
    sidebarEnabled: true,
    IconComponent: HandHoldingEur,
    featureKey: constants.actionKeys.INBOUND_INVOICES,
    renderComponent: renderProps => <AsyncInboundInvoicePage {...renderProps} />
  },
  {
    text: 'Saajat',
    path: routePaths.PATH_RECEIVERS,
    exact: false,
    sidebarEnabled: true,
    IconComponent: HandHoldingEur,
    iconProps: {
      flip: 'horizontal'
    },
    featureKey: constants.actionKeys.RECEIVERS,
    renderComponent: renderProps => <AsyncReceiverPage {...renderProps} />
  },
  {
    text: 'Ostotilaukset',
    path: routePaths.PATH_PURCHASE_ORDERS,
    exact: false,
    sidebarEnabled: true,
    icon: faShoppingCart,
    featureKey: constants.actionKeys.PURCHASE_ORDERS,
    renderComponent: renderProps => <AsyncPurchaseOrderPage {...renderProps} />
  },
  {
    text: 'Kuitit',
    path: routePaths.PATH_RECEIPTS,
    exact: false,
    sidebarEnabled: true,
    icon: faReceipt,
    featureKey: constants.actionKeys.RECEIPT,
    renderComponent: renderProps => <AsyncReceiptPage {...renderProps} />
  },
  {
    text: 'Kassa',
    path: routePaths.PATH_CASH_RECEIPTS,
    exact: false,
    sidebarEnabled: true,
    icon: faCashRegister,
    featureKey: constants.actionKeys.CASH_RECEIPTS,
    renderComponent: renderProps => <AsyncCashReceiptPage {...renderProps} />
  },
  {
    text: 'Kalenteri',
    path: routePaths.PATH_CALENDAR,
    exact: true,
    sidebarEnabled: true,
    icon: faCalendarAlt,
    featureKey: constants.actionKeys.CALENDAR,
    renderComponent: renderProps => <AsyncCalendarPage {...renderProps} />
  },
  {
    text: 'Tarjoukset',
    path: routePaths.PATH_OFFERS,
    exact: false,
    sidebarEnabled: true,
    icon: faHandshake,
    featureKey: constants.actionKeys.OFFERS,
    renderComponent: renderProps => <AsyncOfferPage {...renderProps} />
  },
  {
    text: 'Tarviketarjoukset',
    path: routePaths.PATH_SUPPLY_OFFERS,
    exact: false,
    sidebarEnabled: true,
    icon: faHammer,
    featureKey: constants.actionKeys.SUPPLY_OFFERS,
    renderComponent: renderProps => <AsyncSupplyOfferPage {...renderProps} />
  },
  {
    text: ticketTitlesCapitalized.pluralBasic,
    path: routePaths.PATH_TICKETS,
    exact: false,
    sidebarEnabled: true,
    icon: faTicketAlt,
    featureKey: constants.actionKeys.TICKETS,
    renderComponent: renderProps => <AsyncTicketPage {...renderProps} />
  },
  {
    text: systemMessageTitlesCapitalized.pluralBasic,
    path: routePaths.PATH_SYSTEM_MESSAGES,
    exact: false,
    sidebarEnabled: true,
    icon: faBullhorn,
    featureKey: constants.actionKeys.SYSTEM_MESSAGES,
    renderComponent: renderProps => <AsyncSystemMessagePage {...renderProps} />
  },
  {
    text: 'Palkat',
    path: routePaths.PATH_SALARIES,
    exact: false,
    sidebarEnabled: true,
    icon: faCircleEuro,
    featureKey: constants.actionKeys.SALARY_CALCULATION,
    renderComponent: renderProps => <AsyncSalaryPage {...renderProps} />
  },
  {
    text: 'Tiedostot',
    path: routePaths.PATH_FILES,
    exact: false,
    sidebarEnabled: false,
    icon: faFile,
    featureKey: constants.actionKeys.FILES,
    renderComponent: renderProps => <AsyncFilePage {...renderProps} />
  },
  {
    text: 'Raportit',
    path: routePaths.PATH_REPORTS,
    exact: false,
    sidebarEnabled: true,
    icon: faChartLine,
    featureKey: constants.actionKeys.REPORTS,
    renderComponent: renderProps => <AsyncReportPage {...renderProps} />
  },
  {
    text: 'Talous',
    path: routePaths.PATH_FINANCE,
    exact: false,
    sidebarEnabled: true,
    icon: faEuroSign,
    featureKey: constants.actionKeys.FINANCE,
    renderComponent: renderProps => <AsyncFinancePage {...renderProps} />
  },
  {
    text: 'Asetukset',
    path: routePaths.PATH_SETTINGS,
    exact: false,
    sidebarEnabled: false,
    icon: faCogs,
    featureKey: constants.actionKeys.SETTINGS,
    renderComponent: renderProps => <AsyncSettingsPage {...renderProps} />
  },
  {
    text: 'Asetusvelho',
    path: routePaths.PATH_SETTINGSWIZARD,
    exact: false,
    sidebarEnabled: false,
    icon: null,
    featureKey: constants.actionKeys.SETTINGS_WIZARD,
    renderComponent: renderProps => <AsyncSettingsWizardPage {...renderProps} />
  },
  {
    text: 'Käyttö estetty',
    path: routePaths.PATH_CREDITS,
    exact: false,
    sidebarEnabled: false,
    icon: null,
    renderComponent: renderProps => <AsyncCreditActionsPage {...renderProps} />
  },
  {
    text: 'Suosittelut',
    path: routePaths.PATH_INVITATIONS,
    exact: false,
    sidebarEnabled: false,
    icon: faBullhorn,
    renderComponent: renderProps => <AsyncInvitationPage {...renderProps} />
  },
  {
    text: 'Omat asetukset',
    path: routePaths.PATH_USER_ACCOUNT,
    exact: true,
    sidebarEnabled: false,
    icon: faUserEdit,
    featureKey: constants.actionKeys.USER_ACCOUNT,
    renderComponent: renderProps => <AsyncUserPage {...renderProps} />
  },
  {
    text: 'Työaikakirjaukset',
    path: routePaths.PATH_TIME_RECORDS,
    exact: false,
    sidebarEnabled: false,
    icon: faCalendarPlus,
    featureKey: constants.actionKeys.TIME_RECORDS,
    renderComponent: renderProps => <AsyncTimeRecordPage {...renderProps} />
  },
  {
    text: 'Yhteydenotot',
    path: routePaths.PATH_INBOUND_MESSAGES,
    exact: false,
    sidebarEnabled: true,
    icon: faEnvelope,
    featureKey: constants.actionKeys.INBOUND_MESSAGES,
    renderComponent: renderProps => <AsyncInboundMessagePage {...renderProps} />,
    badgeComponent: InboundMessageNavBadge
  },
  {
    text: 'Lomakepohjat',
    path: routePaths.PATH_FORM_TEMPLATES,
    exact: false,
    sidebarEnabled: false,
    icon: faRectangleList,
    featureKey: constants.actionKeys.FORM_TEMPLATES,
    renderComponent: renderProps => <AsyncFormTemplates {...renderProps} />
  },
  {
    text: 'Maksutiedot',
    path: routePaths.PATH_CREDIT_ACTIONS,
    exact: true,
    sidebarEnabled: false,
    featureKey: constants.actionKeys.SYSTEM_CUSTOMER_CREDIT_ACTIONS,
    renderComponent: renderProps => <AsyncCreditActions {...renderProps} />
  }
]

export const MFA_RELOAD_PAGE_ROUTES = routes.map(route => route.path)

export default routes
