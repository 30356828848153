import { capitalize } from '@evelia/common/helpers'
import { miniSerializeError } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import debugCreator from 'debug'
import isGeneratorFunction from 'is-generator-function'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import noop from 'lodash/noop'
import { eventChannel } from 'redux-saga'
import {
  all,
  call,
  put,
  select,
  take
} from 'redux-saga/effects'

import fileActions from '../actions/fileActions'
import loginActions from '../actions/loginActions'
import sagaErrorHandlerActions from '../actions/sagaErrorHandlerActions'
import { MAX_SAFE_INTEGER, memoTypes } from '../constants'
import { findEmployeeLevelWithId, findEmployeeWithId } from '../selectors/employeeSelectors'
import { getSocket } from '../socket'
import { defaultEmbeddedNormalizer } from './apiHelpers'
import { callFunctionOrContent, formatValidationErrorsList } from './helpers'
import { addErrorNotification, addSuccessNotification } from './notificationHelpers'

const debugSocket = debugCreator('evelia:sockets')
const debugSagaError = debugCreator('evelia:saga')

export const getPromiseHandlersFromData = data => {
  const { resolve = noop, reject = noop } = data || {}
  return { resolve, reject }
}

export function* getRecordsFromState(base) {
  const data = yield select(state => get(state, base))
  return data.entities ?? data.records ?? data.record
}

function* isRecordsInsufficient(base, recordId) {
  const { records, metadata, ids } = yield select(state => get(state, base))
  if(ids) {
    return recordId == null
      ? (!ids.length)
      : ids.every(id => id !== recordId)
  }
  // If array is empty every() returns always true
  return recordId == null
    ? (!records.length || metadata.lastFetched == null)
    : records.every(({ id }) => id !== recordId)
}

const getDetailsMessage = (message, details) => details ? <><b>{message}:</b><br />{details}</> : message

const getErrorMessage = (err, errorMessage, { showValidationErrors } = {}) => {
  if(err?.json?.validationErrors) {
    return showValidationErrors ? getDetailsMessage(errorMessage, formatValidationErrorsList(err.json.validationErrors)) : errorMessage
  }
  return getDetailsMessage(errorMessage, err?.json?.message)
}

export const formatSoapError = errorMessage => err => {
  if(err?.json?.errorInfo) {
    const details = err.json.errorInfo.map((info, i) => <li key={i}>{info.ErrorCode}: {info.ErrorMessage}</li>)
    return getDetailsMessage(err.json.title, <ul>{details}</ul>)
  } else {
    return getErrorMessage(err, errorMessage)
  }
}

export function* genericSagaErrorHandler(err, errorMessage, reject, options) {
  console.error(err)
  console.error(JSON.parse(JSON.stringify(err)))
  if(errorMessage) {
    addErrorNotification(getErrorMessage(err, errorMessage, options))
  }
  yield * handleSagaError(err)
  if(reject) {
    yield call(reject, err)
  }
}

export const getGenericErrorMessage = (err, errorMsg, type) => errorMsg == null
  ? null
  : isFunction(errorMsg) ? errorMsg(err, type) : `Virhe ${errorMsg.toLowerCase()} ${type}`

// TODO: use everywhere possible
export const fetchFlow = ({
  fetchApi,
  actions,
  base,
  errorMsg,
  apiResponseHandler,
  getApiResponseHandler,
  shouldPerformRequest = isRecordsInsufficient,
  preShouldPerformRequest,
  idField,
  getData,
  updateLastFetched,
  showValidationErrors
}) => function* ({ data = {}, payload, ...rest }) {
  if(payload) {
    data = payload
  }
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    if(getData) {
      data = yield getData(data)
    }
    const performRequest = data.force || !base || (yield shouldPerformRequest(base, data[idField]))
    const prePerformRequest = preShouldPerformRequest ? yield preShouldPerformRequest(data) : true
    let response

    if(prePerformRequest && performRequest) {
      yield put(actions.fetchStart())
      if(actions.tableActions && data?.tableIdentifier) {
        yield put(actions.tableActions.fetchStart(data.tableIdentifier))
      }
      const apiResponse = yield call(fetchApi, data)
      if(apiResponseHandler) {
        response = yield apiResponseHandler(apiResponse)
      } else if(getApiResponseHandler) {
        response = yield getApiResponseHandler(data)(apiResponse)
      } else {
        response = yield put(actions.fetchSuccess(apiResponse, { updateLastFetched }))
      }
    }
    yield call(resolve, response || data)
  } catch(err) {
    yield * genericSagaErrorHandler(err, getGenericErrorMessage(err, errorMsg, 'noudossa'), null, { showValidationErrors })
    if(actions.fetchError) {
      yield put(actions.fetchError(miniSerializeError(err)))
    }
    yield call(reject, err)
  }
}

export const updateFlow = (updateApi, actions, singular, errorMsg, apiResponseHandler) => function* ({ record, data = {}, payload, ...opts }) {
  if(payload) {
    ({ record, ...data } = payload)
  }
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(actions.updateStart(record))
    const updated = yield call(updateApi, record, data)
    let response
    if(apiResponseHandler) {
      response = yield apiResponseHandler(updated, record)
    } else {
      yield put(actions.updateSuccess(updated))
    }
    response = response || updated
    addSuccessNotification(isFunction(singular) ? singular(data, response) : `${singular} tallennettu`)
    yield call(resolve, response)
  } catch(err) {
    yield put(actions.updateError(miniSerializeError(err), record))
    yield * genericSagaErrorHandler(err, getGenericErrorMessage(err, errorMsg, 'päivityksessä'), reject)
    if(data.oldData) {
      yield put(actions.updateSuccess(data.oldData))
    }
  }
}

export const createFlow = (createApi, actions, singular, errorMsg, apiResponseHandler) => function* ({ record, data = {}, payload, ...opts }) {
  if(payload) {
    ({ record, ...data } = payload)
  }
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const created = yield call(createApi, record, data)
    let response
    if(apiResponseHandler) {
      response = yield apiResponseHandler(created, record)
    } else {
      yield put(actions.createSuccess(created, record.id))
    }
    response = response || created
    addSuccessNotification(isFunction(singular) ? singular(data, response) : `${singular} luotu`)
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, getGenericErrorMessage(err, errorMsg, 'luonnissa'), reject)
  }
}

const getRecordFromState = function* (base, id, key = 'id') {
  const records = yield getRecordsFromState(base)
  if(Array.isArray(records)) {
    return records.find(item => item[key] === id)
  }
  return records[id]
}

export const deleteFlow = ({
  deleteApi,
  actions,
  singular,
  errorMsg,
  base,
  key = 'id'
}) => function* ({ record, data = {}, payload }) {
  if(payload) {
    ({ record, ...data } = payload)
  }
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const toDelete = yield getRecordFromState(base, record[key], key)
    yield put(actions.deleteStart(toDelete))
    yield call(deleteApi, record, data)
    yield put(actions.deleteSuccess(toDelete))

    if(actions.tableActions) {
      // Ensure that deleted id is not on tableOption ids
      const tableOptions = yield select(state => get(state, base).tableOptions)
      for(const [tableOptionKey, value] of Object.entries(tableOptions)) {
        const { ids, ...restOptions } = value
        const clearedIds = ids.filter(id => id !== record[key])
        yield put(actions.tableActions.updateOptions({ ...restOptions, q: null, ids: clearedIds }, tableOptionKey))
      }
    }

    addSuccessNotification(`${singular} poistettu`)
    yield call(resolve)
  } catch(err) {
    yield put(actions.deleteError(err, record))
    yield * genericSagaErrorHandler(err, getGenericErrorMessage(err, errorMsg, 'poistossa'), reject)
  }
}

export const searchFlow = (searchApi, actions, errorMsg, apiResponseHandler) => function* searchFlow({ searchTerm, data = {}, ...opts }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(actions.searchStart())
    let result = yield call(searchApi, searchTerm, data)
    if(apiResponseHandler) {
      const apiResponseHandlerResult = yield apiResponseHandler(result, searchTerm, data)
      result = apiResponseHandlerResult || result
    } else {
      yield put(actions.fetchSuccess(result))
      yield put(actions.searchSuccess(result, searchTerm))
    }
    yield call(resolve, result)
  } catch(err) {
    yield put(actions.fetchError(miniSerializeError(err)))
    yield put(actions.searchError(err, searchTerm))
    yield * genericSagaErrorHandler(err, getGenericErrorMessage(err, errorMsg, 'hakemisessa'), reject)
  }
}

export const actionFlow = ({ apiFunction, apiResponseHandler, successMsg, errorMsg }) => function* actionFlow({ record, data }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(apiFunction, record)
    if(apiResponseHandler) {
      yield * apiResponseHandler(response)
    }
    addSuccessNotification(successMsg || 'Toiminto onnistui')
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, errorMsg || 'Toiminto epäonnistui', reject)
  }
}

export const getSocketWatcherFunction = socketChannelCreator => function* watchOnSockets() {
  const socketChannel = yield call(socketChannelCreator, getSocket())
  while(true) {
    try {
      const action = yield take(socketChannel)
      if(isGeneratorFunction(action) || action?.toString() === '[object Generator]') {
        yield * action
      } else {
        yield put(action)
      }
    } catch(err) {
      console.error(err)
    }
  }
}

const createSocketChannel = (namespace, actionMap, normalizer = a => a) => socket => {
  return eventChannel(emit => {
    forEach(actionMap, (callback, key) => {
      socket.on(`${namespace}:${key}`, (id, data) => {
        if(id !== socket.id) {
          debugSocket(`socket on ${namespace}:${key}: `, data)
          try {
            const eventData = callback(normalizer(data))
            if(Array.isArray(eventData)) {
              eventData.forEach(event => emit(event))
            } else {
              emit(eventData)
            }
          } catch(err) {
            Sentry.withScope(scope => {
              scope.setExtras({ namespace, key })
              scope.setTag('type', 'socket')
              Sentry.captureException(err)
            })
          }
        }
      })
    })
    return () => {
      forEach(actionMap, (callback, key) => {
        socket.off(`${namespace}:${key}`)
      })
    }
  })
}

export function createSocketWatcher(namespace, actionMap, normalizer) {
  return getSocketWatcherFunction(createSocketChannel(namespace, actionMap, normalizer))
}

export const getDefaultGeneratorSocketHandlers = (actions, apiHandler, normalizer) => ({
  created: function* (data) {
    yield apiHandler(actions.createSuccess, null)(normalizer(data))
  },
  updated: function* (data) {
    yield apiHandler(actions.updateSuccess, null)(normalizer(data))
  },
  deleted: function* (data) {
    yield put(actions.deleteSuccess(data))
  }
})

export const createSocketWatcherWithApiHandlerAndNormalizer = (namespace, actions, apiHandler, normalizer = defaultEmbeddedNormalizer) =>
  createSocketWatcherWithGenerator(namespace, getDefaultGeneratorSocketHandlers(actions, apiHandler, normalizer))

export function createSocketWatcherWithGenerator(namespace, actionMap) {
  return getSocketWatcherFunction(createSocketChannel(namespace, actionMap))
}

export const getSubFilesSagas = (mainActions, fetchApi, createApi, deleteApi, updateApi, mainIdFieldName, mainReduxName, messages, apiHandler) => {
  const subFetchFlow = function* ({ data = {} }) {
    const { [mainIdFieldName]: mainId, ...opts } = data
    try {
      yield put(mainActions.files.fetchStart())
      const apiResponse = yield call(fetchApi, mainId, opts)
      if(apiHandler) {
        yield apiHandler(apiResponse)
      } else {
        const [relationObjects, subObjects] = apiResponse
        yield put(mainActions.files.fetchSuccess(relationObjects))
        yield put(fileActions.fetchSuccess(subObjects))
      }
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.fetchError)
    }
  }

  const createApiHandler = function* (apiResponse) {
    const [relationObjects, subObjects] = apiResponse
    yield put(mainActions.files.createSuccess(relationObjects))
    if(subObjects) {
      for(const subObject of subObjects) {
        yield put(fileActions.updateSuccess(subObject))
      }
    }
  }

  const subCreateFlow = createFlow(createApi, mainActions.files, messages.singular, messages.accusative, createApiHandler)

  const subDeleteFlow = function* ({ record, data = {} }) {
    try {
      const toDelete = yield select(state => state[mainReduxName].files.records.find(relationObject => relationObject[mainIdFieldName] === record[mainIdFieldName] && relationObject.fileId === record.fileId))
      yield call(deleteApi, toDelete)
      yield put(mainActions.files.deleteSuccess(toDelete))
      addSuccessNotification(messages.deleteSuccess)
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.deleteError)
    }
  }

  const subUpdateFlow = fileLinkUpdateFlow(updateApi, mainActions, messages.base)

  return {
    subFetchFlow,
    subCreateFlow,
    subDeleteFlow,
    subUpdateFlow
  }
}

export const getSubEntitySagas = (mainActions, subActions, fetchApi, createApi, deleteApi, mainIdFieldName, subIdFieldName, mainReduxName, subReduxName, messages, apiHandler) => {
  const subFetchFlow = function* ({ data = {} }) {
    const { [mainIdFieldName]: mainId, ...opts } = data
    try {
      yield put(mainActions[subReduxName].fetchStart())
      const apiResponse = yield call(fetchApi, mainId, opts)
      if(apiHandler) {
        yield apiHandler(apiResponse)
      } else {
        const [relationObjects, subObjects] = apiResponse
        yield put(mainActions[subReduxName].fetchSuccess(relationObjects))
        yield put(subActions.fetchSuccess(subObjects))
      }
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.fetchError)
    }
  }

  const subCreateFlow = createFlow(createApi, mainActions[subReduxName], messages.singular, messages.accusative, apiHandler)

  const subDeleteFlow = function* ({ record, data = {} }) {
    try {
      const toDelete = yield select(state => state[mainReduxName][subReduxName].records.find(relationObject => relationObject[mainIdFieldName] === record[mainIdFieldName] && relationObject[subIdFieldName] === record[subIdFieldName]))
      yield call(deleteApi, toDelete)
      yield put(mainActions[subReduxName].deleteSuccess(toDelete))
      addSuccessNotification(messages.deleteSuccess)
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.deleteError)
    }
  }

  return {
    subFetchFlow,
    subCreateFlow,
    subDeleteFlow
  }
}

export const getSubSagas = (mainData, apiFunctions, messages, apiHandler, mutateApiHandler) => {
  const {
    mainActions,
    mainReduxName,
    subReduxName,
    pathField
  } = mainData

  const {
    fetchApi,
    createApi,
    updateApi,
    deleteApi
  } = apiFunctions

  const subFetchFlow = function* ({ data = {} }) {
    try {
      yield put(mainActions[subReduxName].fetchStart())
      const apiResponse = yield call(fetchApi, data)
      if(apiHandler) {
        yield apiHandler(mainActions[subReduxName].fetchSuccess)(apiResponse)
      } else {
        yield put(mainActions[subReduxName].fetchSuccess(apiResponse))
      }
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.fetchError)
    }
  }

  const mutateHandler = mutateApiHandler || apiHandler

  const subCreateFlow = createFlow(createApi, mainActions[subReduxName], messages.singular, messages.accusative, mutateHandler?.(mainActions[subReduxName].createSuccess))
  const subUpdateFlow = updateFlow(updateApi, mainActions[subReduxName], messages.singular, messages.accusative, mutateHandler?.(mainActions[subReduxName].updateSuccess))

  const subDeleteFlow = function* ({ record, data = {} }) {
    const { resolve, reject } = getPromiseHandlersFromData(data)
    try {
      const toDelete = yield select(state => state[mainReduxName][subReduxName].records.find(relationObject => relationObject.id === record.id))
      yield call(deleteApi, toDelete, data)
      yield put(mainActions[subReduxName].deleteSuccess(toDelete))
      addSuccessNotification(messages.deleteSuccess)
      yield call(resolve, toDelete)
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.deleteError, reject)
    }
  }

  const subDeleteRecursiveFlow = function* ({ record, data = {} }) {
    const { resolve, reject } = getPromiseHandlersFromData(data)
    try {
      const records = yield select(state => state[mainReduxName][subReduxName].records)
      const toDelete = records.find(relationObject => relationObject.id === record.id)
      yield call(deleteApi, toDelete, data)
      const elementsToDelete = records.filter(relationObject => {
        const deletedIndex = relationObject[pathField].findIndex(id => id === toDelete.id)
        const ownIndex = relationObject[pathField].findIndex(id => id === relationObject.id)
        if(deletedIndex >= 0) {
          return deletedIndex < ownIndex
        }
        return false
      })

      for(const deletedItem of [...elementsToDelete, toDelete]) {
        yield put(mainActions[subReduxName].deleteSuccess(deletedItem))
      }
      addSuccessNotification(messages.deleteSuccess)
      yield call(resolve, toDelete)
    } catch(err) {
      yield * genericSagaErrorHandler(err, messages.deleteError, reject)
    }
  }

  return {
    subFetchFlow,
    subCreateFlow,
    subUpdateFlow,
    subDeleteFlow,
    subDeleteRecursiveFlow
  }
}

export const checkPermission = (getRequiredAccessLevel, flow) => {
  return function* (...args) {
    const accessLevel = yield select(state => {
      const currentEmployee = findEmployeeWithId(state, state.whoAmI.data.employeeId)
      const employeeLevel = findEmployeeLevelWithId(state, currentEmployee?.employeeLevelId)
      return employeeLevel ? employeeLevel.accessLevel : null
    })
    if(accessLevel >= getRequiredAccessLevel(...args)) {
      yield * flow(...args)
    }
  }
}

export const getMinMemoAccessLevel = ({ data = {} }) => {
  const { memoType, minAccessLevel } = data
  const memoTypeObj = memoTypes[memoType]

  return minAccessLevel ?? memoTypeObj?.minAccessLevel ?? MAX_SAFE_INTEGER
}

export const getSagaProvider = (apiResponses, apiFuncs = {}) => ({
  call(effect, next) {
    if(Object.values(apiFuncs).includes(effect.fn)) {
      return effect.fn(...effect.args).then(a => {
        apiResponses.push(a)
        return a
      })
    } else {
      return effect.fn(...effect.args)
    }
  }
})

export const clearSagaError = function* () {
  yield put(sagaErrorHandlerActions.clearSagaError())
}

const excludeLogOutGlobalErrorHttpStatusCodes = [
  412, // Precondition Failed
  402, // Payment required
  403 // System customer disabled
]
export const handleSagaError = function* (err, handled) {
  debugSagaError(err, err.message, err.response)
  if(err.response && err.response.status !== 504 && (handled || !err?.json?.globalError)) {
    return
  }
  // error comes from backend
  if(err.response) {
    const errorObject = {
      status: err.response.status,
      url: err.response.url,
      ...err?.json
    }
    // Global error (authentication error) and status is not listed in excluded status codes -> logout to clear redux state
    if(err.json?.globalError && !excludeLogOutGlobalErrorHttpStatusCodes.includes(err.response.status)) {
      yield put(loginActions.logoutSuccess())
    }
    yield put(sagaErrorHandlerActions.handleSagaError(errorObject))
  } else { // frontend error
    const errorObject = {
      status: null,
      message: err.message,
      ...err?.json
    }
    yield put(sagaErrorHandlerActions.handleSagaError(errorObject))
    if(!err?.json?.handled) {
      throw err
    }
  }
}

export const defaultApiResponseHandler = mainAction => function* (response) {
  const data = response.records || response.record || response.data || response
  yield put(mainAction(data))
  return data
}

export const fetchStatsFlow = (action, fetchStats, idField) => function* ({ record, data = {} }) {
  const id = data ? data[idField] || record ? record[idField] : null : null
  try {
    yield put(action.fetchStatsStart())
    const stats = yield call(fetchStats, { [idField]: id })
    yield put(action.fetchStatsSuccess(stats))
  } catch(err) {
    yield put(action.fetchStatsError(err))
    yield * genericSagaErrorHandler(err)
  }
}

export const createActionFlow = (apiAction, actions, apiResponseHandler) => function* ({ record, data = {}, payload, ...opts }) {
  if(payload) {
    ({ record, ...data } = payload)
  }
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const responseBody = record
    const urlExpand = data
    const response = yield call(apiAction, responseBody, urlExpand)
    const handledApiResponse = apiResponseHandler ? yield * apiResponseHandler(actions.fetchSuccess)(response) : yield put(actions.fetchSuccess(response))
    addSuccessNotification(data.getSuccessMessage ? callFunctionOrContent(data.getSuccessMessage, response) : 'Toiminto onnistui')
    yield call(resolve, handledApiResponse)
  } catch(err) {
    yield put(actions.fetchError?.(err))
    yield * genericSagaErrorHandler(err, data.getErrorMessage ? callFunctionOrContent(data.getErrorMessage, err) : 'Toiminto epäonnistui', reject)
  }
}

export const updateRowRankFlow = (apiAction, action, properties) => function* ({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  const { actionData, ...rankData } = data

  const affectedIds = record.map(record => record.id)
  const backup = (yield select(state => get(state, properties).records.filter(row => affectedIds.includes(row.id))))
  try {
    yield all(record.map(recordToUpdate => put(action.updateStart(recordToUpdate))))
    const rows = yield call(apiAction, rankData, actionData)
    const updatedRows = rows.data ?? rows.records ?? rows
    yield all(updatedRows.map(updatedRow => put(action.updateSuccess(updatedRow))))
    addSuccessNotification('Rivien uudelleenjärjestely onnistui')
    yield call(resolve, updatedRows)
  } catch(err) {
    yield all(backup.map(backupRow => put(action.updateSuccess(backupRow))))
    yield * genericSagaErrorHandler(err, 'Virhe rivien järjestyksen muokkaamisessa', reject)
  }
}

export const createPreviewsFlow = (createPreviewsStart, apiAction, createPreviewsSuccess, createPreviewsError) => function* ({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(createPreviewsStart())
    const preview = yield call(apiAction, record, data)
    yield put(createPreviewsSuccess(preview))
    addSuccessNotification('Pdf luotu')
    yield call(resolve, preview)
  } catch(err) {
    yield put(createPreviewsError(err))
    yield * genericSagaErrorHandler(err, 'Virhe esikatselun luomisessa', reject)
  }
}

export const generateReportFlow = apiAction => function* ({ data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    const response = yield call(apiAction, data)
    yield call(resolve, response)
  } catch(err) {
    yield * genericSagaErrorHandler(err, 'Raportin luonti epäonnistui', reject)
  }
}

export const fileLinkUpdateFlow = (apiAction, action, propertyText) => function* ({ record, data = {} }) {
  const { resolve, reject } = getPromiseHandlersFromData(data)
  try {
    yield put(action.files.updateStart(record))
    const returnValue = yield call(apiAction, record)
    yield put(action.files.updateSuccess(returnValue))
    addSuccessNotification(`${capitalize(propertyText)} tiedosto päivitetty`)
    yield call(resolve, returnValue)
  } catch(err) {
    yield put(action.files.updateError(err, record))
    yield * genericSagaErrorHandler(err, `Virhe ${(propertyText)} tiedoston muokkaamisessa`, reject)
  }
}
