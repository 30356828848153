import { EmploymentModel } from '@evelia/common/types'
import {
  createEntityAdapter
} from '@reduxjs/toolkit'

import { createGenericSlice } from '../helpers/sliceHelpers'
import { EveliaRootState } from '../reducerTypes'

const employmentAdapter = createEntityAdapter<EmploymentModel>({})

const employmentSlice = createGenericSlice({
  name: 'employments',
  entityAdapter: employmentAdapter
})

export const employmentEntitySelectors = employmentAdapter.getSelectors<EveliaRootState>(state => state.wageEarners.employments)
export const employmentActions = employmentSlice.actions

export default employmentSlice
