import { emptyArray } from '@evelia/common/constants'
import { toIdMap } from '@evelia/common/helpers'

import { AuthorModel } from '../api/rtk/authorApi'
import { createArgumentSelector, createEveliaSelector, getFilterItemsByFieldSelector, getFindItemByIdSelector } from '../helpers/typedSelectorHelpers'
import { EveliaRootState } from '../reducerTypes'

export const getAuthorsFromArgument = (arg: EveliaRootState | AuthorModel[]) => Array.isArray(arg) ? arg : arg.authors.records

export const findAuthorWithId = getFindItemByIdSelector(getAuthorsFromArgument)

export const findAuthorsWithEmployeeId = getFilterItemsByFieldSelector(getAuthorsFromArgument, ['employeeId'])

export const findAuthorsOfCurrentEmployee = createEveliaSelector(
  getAuthorsFromArgument,
  state => state.whoAmI.data.employeeId,
  (authors, currentEmployeeId) => currentEmployeeId ? findAuthorsWithEmployeeId(authors, currentEmployeeId) : emptyArray
)

export const getAuthorsMap = createEveliaSelector(
  getAuthorsFromArgument,
  authors => toIdMap(authors))

export const getIsOwnAuthor = createArgumentSelector(
  [
    findAuthorsOfCurrentEmployee,
    (__state, authorId: number) => authorId
  ],
  (authors, authorId) => authors.map(author => author.id).includes(authorId)
)
