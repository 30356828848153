import { BaseSyntheticEvent, KeyboardEvent, LegacyRef, MouseEvent } from 'react'
import {
  capitalize,
  formatThousandsSeparatedBySpaces,
  formatWithUnit,
  getCoefficient,
  getDiscountCoefficient,
  getSelf,
  round1decimals,
  round8decimals,
  roundIntegerAndFormatThousandsSeparatedBySpaces,
  sortByProperty
} from '@evelia/common/helpers'
import { WarehouseModel } from '@evelia/common/types'
import {
  faCheck,
  faLock,
  faQuestionCircle,
  faThumbsDown,
  faThumbsUp,
  faUnlock,
  faXmark,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Buffer } from 'buffer'
import chroma from 'chroma-js'
import constant from 'lodash/constant'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import mergeWith from 'lodash/mergeWith'
import memoize from 'micro-memoize'
import type { Primitive } from 'type-fest'

import { fileUrl } from '../api/fileApi'
import { keyCodes, NEW_ENTRY_ID } from '../constants'

export const capitalizeObjectValues = <T extends Record<string, string>>(object: T): T =>
  Object.entries(object)
    .reduce((acc, [key, value]) => {
      acc[key] = capitalize(value)
      return acc
    }, {}) as T

export const getStringOrNull = (value: string | null) => (value == null || value === '') ? null : value

export const isNewlyCreated = (object: { id: number }) => object && (!object.id || object.id === NEW_ENTRY_ID || object.id < 0)

const numberSingleDecimalWithComma = (value: string | number) => {
  if(value == null || value === '' || Number.isNaN(value)) {
    return null
  }
  const numeric = round1decimals(value)
  return numeric.toString().replace('.', ',')
}

// @ts-expect-error formatWithUnit is not typed
export const formatWithUnitMemoized = memoize((unit, format?: (value: string | number) => number | string | null) => formatWithUnit(unit, format))
export const formatEur = formatWithUnitMemoized('€', formatThousandsSeparatedBySpaces)
export const formatPercent = formatWithUnitMemoized('%', formatThousandsSeparatedBySpaces)
export const formatRoundedPercent = formatWithUnitMemoized('%', roundIntegerAndFormatThousandsSeparatedBySpaces)
export const formatSingleDecimalPercent = formatWithUnitMemoized('%', numberSingleDecimalWithComma)
export const formatHours = formatWithUnitMemoized('h')
export const formatDays = formatWithUnitMemoized('pv', round1decimals)

export const formatDiff = valueFormatter => value => `${value >= 0 ? '+' : ''}${valueFormatter(value)}`

export const checkedIcon = (checked: boolean) => {
  return iconFormatter(faCheck, faXmark, 'text-success', 'text-danger')(checked)
}
export const checkedOrNullIcon = (checked: boolean, opts = {}) => iconFormatter(faCheck, null, 'text-success')(checked, opts)

export const lockedIcon = (locked: boolean) => {
  return iconFormatter(faLock, faUnlock, 'text-success', 'text-warning')(locked)
}

export const thumbIcon = (checked: boolean) =>
  checked == null ? <FontAwesomeIcon icon={faQuestionCircle} /> : iconFormatter(faThumbsUp, faThumbsDown, 'text-success', 'text-danger')(checked)

export const iconFormatter = (trueIcon: IconDefinition, falseIcon: IconDefinition | null, trueClass: string, falseClass?: string) => (checked: boolean, opts: Omit<FontAwesomeIconProps, 'icon'> = {}) => {
  const icon = checked ? trueIcon : falseIcon
  const colorClass = checked ? trueClass : falseClass
  return icon ? <FontAwesomeIcon {...opts} icon={icon} className={colorClass + ' fa-fw'} /> : null
}

export const scrollContentToBottom = () => {
  const contentElement = document.getElementById('app-content')
  contentElement?.scrollTo({ top: contentElement.scrollHeight, behavior: 'smooth' })
}

export const scrollToTop = () => {
  const contentElement = document.getElementById('app-content')
  contentElement?.scrollTo(0, 0)
}

export const getElementPosition = (element: HTMLElement) => {
  const clientRect = element.getBoundingClientRect()
  return {
    left: clientRect.left + document.body.scrollLeft,
    top: clientRect.top + document.body.scrollTop
  }
}

export const getCssVariable = (variable: string) =>
  getComputedStyle(document.documentElement)
    .getPropertyValue(variable)

export const getBsColor = (colorName: string) => getCssVariable(`--bs-${colorName}`)?.trim()

export const screenLargerThan = (minWidth: number) =>
  window.matchMedia(`screen and (min-width: ${minWidth}px)`).matches

export const mapToOptions = (data, { valueKey = 'id', displayKey = 'name' } = {}) => data.map(
  object => ({ value: object[valueKey], text: object[displayKey] })
)

export const mapProductLineOptions = memoize(productLines => productLines.map(productLine => (
  { value: productLine.id, text: `${productLine.code} - ${productLine.name}` }
)))

export const mapBankAccountOptions = memoize(bankAccounts => bankAccounts.filter(bankAccount => bankAccount.id > 0).map(bankAccount => (
  { value: bankAccount.id, text: `${bankAccount.name} - ${bankAccount.iban}` }
)))

export const mapInvoiceBankAccountOptions = memoize(bankAccounts => mapBankAccountOptions(bankAccounts.filter(bankAccount => bankAccount.isInvoiceBankAccount)))

export const mapBankAccountAccountOptions = memoize((bankAccounts, vatCodeId) => bankAccounts.map(bankAccount => (
  { value: bankAccount.account, text: `${bankAccount.name}: ${bankAccount.iban}`, vatCodeId }
)))

export const mapAccountOptions = memoize(accounts => accounts.map(account => (
  { value: account.id, text: `${account.id} - ${account.name}` }
)))

export const mapAccountWithTypeOptions = memoize(accounts => accounts.map(account => (
  { value: account.id, text: `${account.id} - ${account.name}`, type: account.accountType }
)))

export const mapWarehouseOptions = memoize((warehouses: WarehouseModel[]) => warehouses.sort(sortByProperty('warehousePath')).map(warehouse => (
  { value: warehouse.id, text: `${''.padStart(warehouse.warehousePath.length - 1, '-')} ${warehouse.name}` }
)))

export const mapAccountTypes = memoize(accountTypes => Object.entries(accountTypes)
  .map(([accountType, accountTypeTitle]) => ({
    value: Number(accountType), text: accountTypeTitle
  }))
)

export const mapObjectToOptions = (data: Record<string, object>, keyFormatter: (key: string) => string = getSelf) => Object.entries(data)
  .map(([key, values]) => ({
    value: keyFormatter(key), ...values
  }))
export const mapWorkTypes = memoize(workTypes => workTypes.map(workType => (
  { value: workType.id, text: workType.name }
))
)
export const mapRecordNameOptions = memoize((records: { name: string, id?: number | string }[]) => records.map((data, index) => (
  { value: data.id || index, text: data.name }
))
)

export const difference = <T, >(a: T[], b?: Iterable<T>) => {
  const s = new Set(b)
  return a.filter(x => !s.has(x))
}

export const getUrlParams = (search: string) => {
  const query = search.startsWith('?') ? search.substring(1) : search
  const params = query.split('&')
  return params.reduce((acc, param) => {
    const paramData = param.split('=')
    acc[decodeURIComponent(paramData[0])] = decodeURIComponent(paramData[1])
    return acc
  }, {})
}

export const promiseFileReader = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      resolve(reader.result)
    })
    reader.addEventListener('error', reject)
    reader.readAsDataURL(blob)
  })
}

export const fileToBase64 = (file: File) => new Promise<ArrayBuffer | string | null>((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

export const readableStreamToBase64 = async(readableStream: NodeJS.ReadableStream) => {
  const buffers: Uint8Array[] = []
  for await (const data of readableStream) {
    buffers.push(data as Uint8Array)
  }
  const finalBuffer = Buffer.concat(buffers)
  return finalBuffer.toString('base64')
}

export const scrollToBottom = () => {
  const contentElement = document.getElementById('app-content') as HTMLElement
  if(contentElement?.scrollTo) {
    contentElement.scrollTo(0, contentElement.scrollHeight)
  } else {
    contentElement.scrollTop = contentElement?.scrollHeight
  }
}

type PrimitiveOrObject = Primitive | object

export const callFunctionOrContent = (content: PrimitiveOrObject | ((...args: PrimitiveOrObject[]) => unknown), ...args: PrimitiveOrObject[]) => isFunction(content) ? content(...args) : content

export type RenderNode = React.ReactNode | (() => React.ReactNode)
export const callRenderFunctionOrContent = (renderNode: RenderNode) => isFunction(renderNode) ? renderNode() : renderNode

const discardNullValues = (value: PrimitiveOrObject) => {
  if(value != null) {
    return value
  }
}

export const mergeKeepNonNulls = (object: object, other: object) =>
  mergeWith({ ...object }, other, discardNullValues)

export const idItemChanged = (prevItem: { id: number | string }, newItem: { id: number | string }) => (!prevItem && newItem) || (prevItem && newItem && prevItem.id !== newItem.id)

const downloadFileUrl = (dataUrl: string, fileName: string, openFileOnNewTab = false) => {
  const link = document.createElement('a')
  link.href = dataUrl
  if(openFileOnNewTab) {
    link.target = '_blank'
  } else {
    link.download = fileName
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadDataUrl = (dataUrl: string, fileName: string) => {
  downloadFileUrl(dataUrl, fileName)
}

export const openFileOnNewTab = (fileId: string, fileName: string) => {
  if(sessionStorage.getItem('isCypress')) {
    return
  }
  const url = fileUrl({ id: fileId, fileName })
  downloadFileUrl(url, fileName, true)
}

export const toCsvDataUri = (data: string | number | boolean) => `data:text/csv;charset=UTF-8,%EF%BB%BF${encodeURIComponent(data)}`

export const checkKeyCodes = (...codes: number[]) => (e: KeyboardEvent) => codes.includes(e.keyCode)
export const checkIfEnter = checkKeyCodes(keyCodes.ENTER)
export const preventEnter = (e: KeyboardEvent) => {
  if(checkIfEnter(e)) {
    e.preventDefault()
    return true
  }
  return false
}

export const isModifiedEvent = (event: KeyboardEvent | MouseEvent) => {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

export const stopPropagationEvent = (event: BaseSyntheticEvent) => event.stopPropagation()

export const textToBooleanMaybe = (text: 'true' | 'false' | boolean) => {
  if(text === 'true') {
    return true
  } else if(text === 'false') {
    return false
  }
  return text
}

export const calculateVatlessPrice = (price = 0, vatRate = 0) => round8decimals(price / getCoefficient(vatRate))
export const calculateVatPrice = (price = 0, vatRate = 0) => round8decimals(price * getCoefficient(vatRate))
export const calculateDiscountedPrice = (price = 0, discount = 0) => round8decimals(price * getDiscountCoefficient(discount))

export const hashCode = (str: string) =>
  [...str].reduce((acc, char) =>
    ((acc << 5) - acc) + char.charCodeAt(0) | 0
  , 0)

export const mapUniqueValues = <T extends object, >(array: T[], field: string, includeNull = false) => [...new Set(array.map(value => value[field]).filter(includeNull ? constant(true) : Boolean))]

export const ellipsis = (string: string, maxLength: number, offset = 0) => {
  if(!string) { return string }
  if(maxLength < 1) { return string }
  if(string.length <= maxLength) { return string }
  if(maxLength === 1) { return string.substring(0, 1) + '...' }

  const midpoint = Math.ceil(string.length / 2)
  const toRemove = string.length - maxLength
  const leftSide = Math.ceil(toRemove / 2 - offset)
  const rightSide = toRemove - leftSide
  return `${string.substring(0, midpoint - leftSide)}...${string.substring(midpoint + rightSide)}`
}

export const callPromiseAndTapFunction = (promise: (args: unknown) => Promise<unknown>, thenFunc: () => unknown) => async(args: unknown) => {
  const result = await promise(args)
  await thenFunc()
  return result
}

const hasLowContrast = (color: string) => chroma.contrast(color, '#fff') < 4.5 // chroma's recommendation

export const getNodeColorProps = (color: string | null) => {
  if(isString(color)) {
    if(color.startsWith?.('#')) {
      const useDarkText = hasLowContrast(color)
      const setViaRef: LegacyRef<unknown> = (el: HTMLElement) => {
        el?.style?.setProperty('background-color', color, 'important')
        el?.style?.setProperty('color', useDarkText ? '#000' : '#fff', 'important')
      }
      return {
        innerRef: setViaRef,
        ref: setViaRef,
        style: {
          backgroundColor: `${color} !important`,
          color: `${useDarkText ? '#000' : '#fff'}`
        }
      }
    }
    const setViaRef = (el: HTMLElement) => {
      // "Please note that when using Bootstrap’s default .bg-light, you’ll likely need a text color utility like .text-dark for proper styling.
      // This is because background utilities do not set anything but background-color."
      // https://getbootstrap.com/docs/5.1/components/badge/#background-colors
      // So when using light bootsrap colors like warning, text color will not be changed by default
      const backgroundColor = el ? window.getComputedStyle(el).backgroundColor : null
      if(backgroundColor && chroma.valid(backgroundColor)) {
        const textColor = hasLowContrast(backgroundColor) ? '#000' : '#fff'
        el?.style?.setProperty('color', textColor, 'important')
      }
    }
    return {
      innerRef: setViaRef,
      color
    }
  }
  return {}
}

const userAgentIncludes = (text: string) => !!navigator?.userAgent.toLowerCase().includes(text)
export const isChrome = () => userAgentIncludes('chrome')
export const isFirefox = () => userAgentIncludes('firefox')
// Chrome's user agent might contain word "safari"
export const isSafari = () => userAgentIncludes('safari') && !isChrome()

export const formatValidationErrorsList = (validationErrors: { msg: string }[]) => validationErrors.map((validationError, i) => <li key={i}>{validationError.msg}</li>)

export const delay = (ms = 1000) => new Promise(_resolve => setTimeout(_resolve, ms))

export const schemaDefaults = schema => {
  // There's also schema.default(), but it set's the default values as undefined which is not what we want
  const { default: result, fields } = schema.describe()
  return Object.entries(result)
    // Schema defined default are already set - leave them be
    .filter(([__key, value]) => value === undefined)
    .reduce((acc, [key, __value]) => {
      if(fields[key].nullable) {
        acc[key] = null
      } else if(fields[key].type === 'number') {
        acc[key] = 0
      } else if(fields[key].type === 'array') {
        acc[key] = []
      }
      return acc
    }, result)
}
