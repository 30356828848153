import { BaseSyntheticEvent, useCallback } from 'react'
import { accessLevels } from '@evelia/common/constants'

import './supersearch.scss'
import contactActions from '../../actions/contactActions'
import customerActions from '../../actions/customerActions'
import inboundInvoiceActions from '../../actions/inboundInvoiceActions'
import invoiceActions from '../../actions/invoiceActions'
import productActions from '../../actions/productActions'
import targetActions from '../../actions/targetActions'
import workActions from '../../actions/workActions'
import SearchInput from '../../components/Search/SearchInput'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import useToggle from '../../hooks/useToggle'
import { useTypedDispatch, useTypedSelector } from '../../reducerTypes'
import { findCurrentEmployeeLevel } from '../../selectors/employeeSelectors'
import SuperSearchResults from './SuperSearchResults'

const SuperSearch = () => {
  const dispatch = useTypedDispatch()
  const accessLevel = useTypedSelector(state => findCurrentEmployeeLevel(state).accessLevel)
  const [resultsOpen, { disable: closeResults, enable: openResults }] = useToggle(false)

  const doSearch = useCallback(searchTerm => {
    if(!searchTerm?.length) {
      return
    }
    openResults()
    dispatch(workActions.searchRequest(searchTerm))
    if(accessLevel > accessLevels.SUBCONTRACTOR) {
      dispatch(customerActions.searchRequest(searchTerm))
      dispatch(targetActions.searchRequest(searchTerm))
      dispatch(contactActions.searchRequest(searchTerm))
    }
    if(accessLevel > accessLevels.RESTRICTED_USER) {
      dispatch(productActions.searchRequest(searchTerm))
    }
    if(accessLevel > accessLevels.USER) {
      // @ts-expect-error invoiceActions searchRequest has different signature than 'basic' actions.searchRequest, I guess?
      dispatch(invoiceActions.searchRequest({ q: searchTerm }))
      // @ts-expect-error inboundInvoiceActions searchRequest has different signature than 'basic' actions.searchRequest, I guess?
      dispatch(inboundInvoiceActions.searchRequest({ q: searchTerm }))
    }
  }, [accessLevel, dispatch, openResults])

  const ignoreClicksOn = useCallback((event: BaseSyntheticEvent) => {
    return event.target.closest?.('#supersearch')
  }, [])

  // The usage of useOnClickOutside's ref is not needed because event.target.closest?.('#supersearch') matches all elements that we wan't ignore already
  useOnClickOutside(closeResults, !resultsOpen, ignoreClicksOn)

  return (
    <div id='supersearch'>
      <SearchInput onSubmit={doSearch} onFocus={openResults} />
      <SuperSearchResults isOpen={resultsOpen} close={closeResults} />
    </div>
  )
}

export default SuperSearch
